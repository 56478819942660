import {useState} from 'react';
import {App} from 'antd';

import {Decision, Team, TemplateRef, ErrorDetailDispatch, ErrorDetail} from 'types';
import {AppDispatch, useAppDispatch} from 'store';

import CreateEditSteps from 'components/steps/_common/CreateEditSteps';
import DescriptionStep from 'components/steps/_common/DescriptionStep';
import NameStep from 'components/steps/_common/NameStep';
import TeamStep from 'components/steps/_common/TeamStep';
import TemplateStep from 'components/steps/_common/TemplateStep';

import {createDecision} from 'features/decisionSlice';
import templateSteps from '../_common/templateSteps';
import {map} from '../../../utils';
import {useTemplates} from '../../../hooks/useTemplates';

const CreateDecisionSteps = (props: {
    team?: Team,
    onSuccess: (decision: Decision) => void
    onCancel: () => void
}) => {
    const {message} = App.useApp();

    const [decision, setDecision] = useState({} as Decision);
    const dispatch: AppDispatch = useAppDispatch();

    const templates = map(useTemplates());
    const template = decision.template?.id ? templates[decision.template.id] : undefined;


    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setDecision({} as Decision);
        }, 0);
    }

    async function onCreate() {
        const newDecision = {
            ...decision
        };
        if (props.team) {
            newDecision.team = {id: props.team.id, type: 'team'}
        }

        const res = await dispatch(createDecision(newDecision as Decision));
        if ((res as ErrorDetailDispatch<Decision>).error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        message.success('Your decision was successfully created.')
        props.onSuccess(res.payload as Decision);
    }

    const steps = [
        {
            title: 'Decision Template',
            content:
                <TemplateStep
                    objectType={'decision'}
                    templateId={decision.template?.id || ''}
                    setTemplate={(templateId: string) => {
                        const newRole = {
                            ...decision, template: {type: 'template', id: templateId} as TemplateRef
                        };
                        setDecision(newRole);
                    }}
                />,
            valid: () => true,
        }
    ];

    if (!props.team) {
        steps.push(
            {
                title: 'Team',
                content:
                    <TeamStep
                        title="What team is making this decision?"
                        teamId={decision.team?.id || ''}
                        setTeamId={teamId => {
                            const newDecision = {...decision, team: {id: teamId, type: 'team'}} as Decision;
                            setDecision(newDecision);
                        }}
                    />,
                valid: () => !!decision.team?.id
            }
        )
    }

    steps.push(
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this decision be called?"
                    name={decision.name || ''}
                    setName={name => {
                        setDecision({...decision, name});
                    }}
                />,
            valid: () => !!decision.name,
        },
        {
            title: 'Description',
            content:
                <DescriptionStep
                    name={'description'}
                    description={decision.description || ''}
                    setDescription={description => {
                        setDecision({...decision, description});
                    }}
                />,
            valid: () => !!decision.description,
        }
    )

    return (
        <>
            <CreateEditSteps
                steps={templateSteps(decision, setDecision, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateDecisionSteps;
