import {Menu, type MenuProps} from 'antd';
import {Link} from 'react-router-dom';
import {TeamOutlined} from '@ant-design/icons';

import type {Person, Team} from 'types';
import {isPersonSystem} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {usePersonTeams} from 'hooks/useTeams';

import {SiderMenuProps} from './types';

function createMyTeamItem(currentUser: Person, teams: Team[]) {
    if (isPersonSystem(currentUser)) {
        return {
            key: 'teams',
            label: <Link to="/teams">All Teams</Link>,
            icon: <TeamOutlined/>
        };
    }

    const teamItems = teams.map(team => {
        return {
            key: `teams/${team.id}`,
            label: <Link to={`/teams/${team.id}`}>{team.name}</Link>,
            icon: <TeamOutlined/>
        };
    }) as MenuProps['items'];

    if (teamItems && teamItems.length === 0) {
        teamItems.push({
            key: 'no-teams',
            label: <span>No Teams Found.</span>,
            disabled: true,
        })
    }

    return {
        key: 'my-teams',
        label: 'My Teams',
        type: 'group',
        children: teamItems
    };
}

const TeamsMenu = (props: SiderMenuProps) => {
    const currentUser = useCurrentUser();
    const myTeams = usePersonTeams(currentUser);

    if (!currentUser) return null;

    const teamItem = createMyTeamItem(currentUser, myTeams);
    const menuItems = teamItem ? [teamItem] as MenuProps['items'] : [];


    return (
        <Menu
            mode="inline"
            items={menuItems}
            selectedKeys={props.selectedKeys}
            onSelect={props.onSelect}
        />
    );
}

export default TeamsMenu;
