import {Card, Space} from 'antd';

import type {AgendaItem, Meeting} from 'types';

import styles from './styles.module.scss';
import {nextOrder} from './utils';

import AgendaItemCard from './AgendaItemCard';
import AddAgendaItemButton from './AddAgendaItemButton';
import {useDroppable} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';
import {useThemeMode} from '../../../../hooks/useThemeMode';

const TeamMeetingColumn = (props: {
    id: string,
    name: string,
    meeting: Meeting
    agendaItems: AgendaItem[]
}) => {
    const themeMode = useThemeMode();
    const {isOver, setNodeRef} = useDroppable({id: props.id});

    const style = {
        border: isOver ? '1px dotted gray' : undefined,
    };

    return (
        <SortableContext
            id={props.id}
            items={props.agendaItems}
        >
            <Card
                ref={setNodeRef}
                style={style}
                className={`team-meeting-column ${themeMode === 'dark' ? styles.columnDark : styles.column}`}
                title={props.name}
                bordered={themeMode === 'dark'}
                extra={
                    <AddAgendaItemButton
                        column={props.name}
                        meeting={props.meeting}
                        order={nextOrder(props.agendaItems)}
                    />
                }
            >
                <Space direction={'vertical'} style={{width: '100%'}}>
                    {props.agendaItems
                        .map(agendaItem => (
                            <AgendaItemCard key={agendaItem.id} agendaItem={agendaItem}/>
                        ))}
                </Space>
            </Card>
        </SortableContext>
    );
}

export default TeamMeetingColumn;
