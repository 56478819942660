import {UniqueIdentifier} from '@dnd-kit/core';
import {getErrorMessage} from 'utils';
import {AgendaItem} from 'types';

const KEY = 'teamMeeting'

export function getDefaultTeamMeeting(teamId: string): string|undefined {
    const configText = localStorage.getItem(KEY);
    if (configText) {
        try {
            const config = JSON.parse(configText);
            return config[teamId] as string|undefined;
        } catch (e) {
            console.log(`Invalid ${KEY} in localStorage: ${getErrorMessage(e)}`);
        }
    }
}

export function setDefaultTeamMeeting(teamId: string, meetingId: string) {
    let config: Record<string, string> = {} ;

    const configText = localStorage.getItem(KEY);
    if (configText) {
        try {
            config = JSON.parse(configText);
        } catch (e) {
            console.log(`Invalid ${KEY} in localStorage: ${getErrorMessage(e)}`);
        }
    }
    config[teamId] = meetingId;
    localStorage.setItem(KEY, JSON.stringify(config));
}

export function nextOrder(agendaItems: AgendaItem[]) {
    let order = 0;
    for (const agendaItem of agendaItems) {
        order = Math.max(agendaItem.order, order);
    }
    return order + 1;
}

/* Note: agendaItems are assumed to be ordered */
export function insertAfter(agendaItems: AgendaItem[], overItem: AgendaItem, activeId: UniqueIdentifier) {
    if (agendaItems.length === 0) return 0;

    if (overItem.id === activeId) {
        return overItem.order;
    }

    const index = agendaItems.findIndex(item => item.id === overItem.id);

    if (index === agendaItems.length - 1) {
        return overItem.order + 1;
    }

    if (agendaItems[index + 1].id === activeId) {
        /* No need to change the order, the item is already in the right place. */
        return agendaItems[index + 1].order;
    }

    const nextOrder = agendaItems[index + 1].order;
    return (overItem.order + nextOrder) / 2;
}

/* Note: agendaItems are assumed to be ordered */
export function insertBefore(agendaItems: AgendaItem[], overItem: AgendaItem, activeId: UniqueIdentifier) {
    if (agendaItems.length === 0) return 0;

    if (overItem.id === activeId) {
        return overItem.order;
    }

    const index = agendaItems.findIndex(item => item.id === overItem.id);

    if (index === 0) {
        return Math.min(overItem.order - 1, 0);
    }

    const prevOrder = agendaItems[index - 1].order;
    return (overItem.order + prevOrder) / 2;
}

export function mapByColumn(agendaItems: AgendaItem[]|undefined) {
    const result : Record<string, AgendaItem[]> = {};
    if (agendaItems) {
        for (const agendaItem of agendaItems) {
            const items = result[agendaItem.column] || [];
            items.push(agendaItem);
            items.sort((a, b) => a.order - b.order);
            result[agendaItem.column] = items;
        }
    }

    return result;
}
