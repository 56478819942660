import {useNavigate} from 'react-router-dom';
import type {Team} from 'types';

import {useTemplate} from 'hooks/useTemplates';

import Object from 'components/Object';
import MarkdownDiv from 'components/MarkdownDiv';
import DrawerDescription from 'components/drawers/common/DrawerDescription';
import FieldDrawerDescription from 'components/drawers/common/FieldDrawerDescription';
import {Button, Flex} from 'antd';
import {isTeamPage} from '../../../utils';


const TeamDrawerDisplay = (props: {
    team: Team
}) => {
    const navigate = useNavigate();
    const template = useTemplate(props.team?.template.id, props.team?.type);
    const templateFields = template ? template.fields : [];

    return (
        <>
            <DrawerDescription title={'Purpose'}>
                <MarkdownDiv markdown={props.team.purpose}/>
            </DrawerDescription>
            {templateFields.map((field, i) => {
                return (
                    <FieldDrawerDescription
                        key={`${field.type}=${field.name}-${i}`}
                        obj={props.team}
                        field={field}
                    />
                );
            })}
            {!isTeamPage(props.team.id) &&
                <Flex justify={'center'}>
                    <Button onClick={() => navigate(`/teams/${props.team.id}`)}>Go To Team</Button>
                </Flex>
            }
            <Object style={{margin: '2em 0'}} object={props.team}/>
        </>
    );
}

export default TeamDrawerDisplay;
