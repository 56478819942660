import {Link} from 'react-router-dom';
import {Card, Table, Typography} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {asDataSource} from 'utils';
import CreateMeetingTypeButton from './CreateMeetingTypeButton';
import {useMeetingTypes} from '../../../hooks/useMeetingTypes';

const MeetingTypesCard = (props: {
    id?: string
}) => {
    const meetingTypes = useMeetingTypes();

    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/settings/meeting-types/${id}`}><span className={'text-nowrap'}>{text}</span></Link>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => text
        }
    ];

    return (
        <Card id={props.id}>
            <Typography.Title level={2}>Meeting Types</Typography.Title>
            <Table
                columns={columns}
                dataSource={asDataSource(meetingTypes)}
                pagination={false}
            />
            <CreateMeetingTypeButton />
        </Card>
    );
}

export default MeetingTypesCard;
