import {Button, Flex, message, Modal, theme} from 'antd';
import {CloseCircleOutlined} from '@ant-design/icons';

import type {AgendaItem} from 'types';
import {getErrorMessage} from 'utils';
import {useOrg} from 'hooks/useOrg';
import {useSetAgendaItems} from 'hooks/meetings';
import {deleteAgendaItem} from 'services/meetings';
import {PropsWithChildren} from 'react';


const DeleteAgendaItemButton = (props: PropsWithChildren<{
    agendaItem: AgendaItem,
    onDelete?: (agendaItem: AgendaItem) => void
    confirm?: boolean
}>) => {
    const setAgendaItems = useSetAgendaItems();

    // FIXME: check for team member
    const org = useOrg();
    if (!org) return null;

    const {token: {colorError}} = theme.useToken();

    async function onOk() {
        try {
            await deleteAgendaItem(org!, props.agendaItem);
            setAgendaItems(agendaItems => {
                return agendaItems ? agendaItems?.filter(item => item.id !== props.agendaItem.id) : [];
            })
        } catch (e) {
            message.error(getErrorMessage(e));
        }
        message.success('The agenda item was successfully deleted.');
        props.onDelete?.(props.agendaItem);
    }

    async function onConfirm() {
        return Modal.confirm({
            title: 'Confirm Agenda Item Deletion',
            content: 'Are you sure you want to permanently delete this agenda item?  This operation cannot be undone.',
            footer: (_, {OkBtn, CancelBtn}) => (
                <>
                    <CancelBtn/>
                    <OkBtn />
                </>
            ),
            okText: 'Delete Agenda Item',
            okButtonProps: {type:'default', danger:true},
            icon: <CloseCircleOutlined style={{color:colorError}}/>,
            onOk
        });
    }

    return (
        <Flex>
            <Button
                danger
                onClick={props.confirm ? onConfirm : onOk}
            >
                {props.children ? props.children : 'Delete Agenda Item'}
            </Button>
        </Flex>
    )
}

export default DeleteAgendaItemButton;
