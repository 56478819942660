import AddRoleButton from './AddRoleButton';
import {Flex, Row, Col} from 'antd';

import type {Role, Team} from 'types';

import {useTeamRoles} from 'hooks/useRoles';

import styles from './styles.module.scss';

import CardEmpty from 'components/CardEmpty';
import RoleCard from './RoleCard';

const RolesTab = (props: {
    team: Team
    onClick?: (role: Role) => void
}) => {
    const roles = useTeamRoles(props.team)
    return (
        <div className={styles.rolesTab}>
            <Flex justify={'end'} className='FixTabBtn' >
                <AddRoleButton team={props.team}/>
            </Flex>
            <Row gutter={16}>
                {roles.length > 0 ?
                    roles.map(role => (
                        <Col xs={24} xl={12} key={role.id}>
                            <RoleCard role={role} onClick={() => props.onClick?.(role)}/>
                        </Col>
                    )) : <CardEmpty/>
                }
            </Row>
        </div>
    );
}

export default RolesTab;
