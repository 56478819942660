import type {Person, Role} from 'types';

import {useTemplate} from 'hooks/useTemplates';

import Object from 'components/Object';
import MarkdownDiv from 'components/MarkdownDiv';
import DrawerDescription from 'components/drawers/common/DrawerDescription';
import FieldDrawerDescription from 'components/drawers/common/FieldDrawerDescription';

import RolePersonDetail from './RolePersonDetail';
import RoleAccountableTo from './RoleAccountableTo';
import RoleAccountableFor from './RoleAccountableFor';

const RoleDrawerDisplay = (props: {
    role: Role,
    person: Person | undefined,
}) => {
    const template = useTemplate(props.role?.template.id, props.role?.type);
    const templateFields = template ? template.fields : [];

    return (
        <>
            <DrawerDescription title={'Accountabilities'}>
                <MarkdownDiv markdown={props.role.accountabilities}/>
            </DrawerDescription>
            <RolePersonDetail person={props.person}/>
            <RoleAccountableTo role={props.role} />
            <RoleAccountableFor role={props.role} />
            {templateFields.map((field, i) => {
                return (
                    <FieldDrawerDescription
                        key={`${field.type}=${field.name}-${i}`}
                        obj={props.role}
                        field={field}
                    />
                );
            })}
            <Object style={{margin: '2em 0'}} object={props.role}/>
        </>
    );
}

export default RoleDrawerDisplay;
