import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {GlobalOutlined, TeamOutlined} from '@ant-design/icons';

import {isPersonSystem} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';

import type {MenuItem, SiderMenuProps} from './types';


const ListsMenu = (props: SiderMenuProps) => {
    const currentUser = useCurrentUser();

    const menuItems = [
        {
            key: 'zones',
            label: <Link to="/zones">Zones</Link>,
            icon: <GlobalOutlined/>
        }
    ] as MenuItem[];

    if (!isPersonSystem(currentUser)) {
        menuItems.push({
            key: 'teams',
            label: <Link to="/teams">All Teams</Link>,
            icon: <TeamOutlined/>
        });
    }

    return (
        <Menu
            mode="inline"
            items={menuItems}
            selectedKeys={props.selectedKeys}
            onSelect={props.onSelect}
        />
    );
}

export default ListsMenu;
