import React from 'react';
import {useNavigate} from 'react-router-dom';

import SettingsCreateButton from 'pages/settings/_common/SettingsCreateButton';

const CreateTemplateButton = () => {
    const navigate = useNavigate();
    return (
        <SettingsCreateButton
            onClick={() => navigate('/settings/create-template')}
        >
            Create Template
        </SettingsCreateButton>
    );
}

export default CreateTemplateButton;
