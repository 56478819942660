import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import {Card, Col, Row, Typography} from 'antd';

import {BaseObject} from 'types';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';
import MarkdownDiv from 'components/MarkdownDiv';
import ObjectDrawer from 'components/drawers/ObjectDrawer';

import {useTeam} from 'hooks/useTeams';
import {useRoles} from 'hooks/useRoles';

import TeamMeetingProvider from 'providers/TeamMeetingProvider';

import DeleteTeamButton from './DeleteTeamButton';
import TeamDetailCard from './cards/TeamDetailCard';
import TeamGraph from './graph/TeamGraph';

import styles from './styles.module.scss';
import TeamMeetingsRow from './TeamMeetingsRow';

const TeamDetailPage = () => {
    useRoles();
    const [selected, setSelected] = useState<BaseObject>()
    let {teamId} = useParams();

    const team = useTeam(teamId);

    if (!team) {
        return null;
    }

    function onClick(obj: BaseObject) {
        setSelected(obj);
    }

    return (
        <TeamMeetingProvider team={team}>
            <MainContent>
                <Row gutter={16} className={styles.TeamRow}>
                    <Col xs={24} md={12}>
                        <Card className={styles.TeamChart}>
                            <div className="card-header">
                                <Typography.Title level={2}>{team.name}</Typography.Title>
                            </div>
                            <div className="card-body">
                                <MarkdownDiv markdown={team.purpose}/>
                                <TeamGraph team={team} onClick={onClick}/>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card className={styles.TeamDetails}>
                            <TeamDetailCard team={team} onClick={onClick}/>
                        </Card>
                    </Col>
                </Row>
                <TeamMeetingsRow team={team} />

                <DeleteTeamButton teamId={team.id}/>
                <Object object={team}/>

                <ObjectDrawer obj={selected} open={!!selected} onClose={() => setSelected(undefined)}/>
            </MainContent>
        </TeamMeetingProvider>
    );
}

export default TeamDetailPage;
