import {useState} from 'react';
import {
    closestCorners,
    DndContext,
    DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, MeasuringStrategy,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {App, Col, Row} from 'antd';

import type {AgendaItem, Meeting, MeetingType} from 'types';
import {getErrorMessage, map} from 'utils';

import {updateAgendaItem} from 'services/meetings';
import {useOrg} from 'hooks/useOrg';

import TeamMeetingColumn from './TeamMeetingColumn';
import {useAgendaItems, useSetAgendaItems} from 'hooks/meetings';
import {insertAfter, insertBefore, mapByColumn,} from './utils';
import AgendaItemCard from './AgendaItemCard';


const TeamMeetingDraggableContainer = (props: {
    meeting: Meeting,
    meetingType: MeetingType
}) => {
    const {message} = App.useApp();

    const org = useOrg();
    const agendaItems = useAgendaItems(props.meeting.id);
    const setAgendaItems = useSetAgendaItems();
    const [activeId, setActiveId] = useState<UniqueIdentifier|null>(null);

    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 0.01,
        },
    });

    const sensors = useSensors(pointerSensor);

    if (!org) return null;

    /* These should be built in the provider */
    const agendaItemsMapById = map(agendaItems);
    const agendaItemsMapByColumn = mapByColumn(agendaItems);

    const update = async (agendaItem: AgendaItem) => {
        try {
            await updateAgendaItem(org, agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
            /* reload page? refresh agenda items? */
        }
    }

    const onDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    }

    const onDragEnd = async (event: DragEndEvent) => {
        const activeItem = agendaItemsMapById[event.active.id];
        if (activeItem) {
            await update(activeItem);
        }
        setActiveId(null);
    }

    const onDragOver = (event: DragOverEvent) => {
        if (!event.over) return;

        const targetColumn = event.over.data.current?.sortable?.containerId;

        setAgendaItems((agendaItems) => {
            const newAgendaItems = agendaItems ? [...agendaItems] : [];
            const activeItem = newAgendaItems.find(item => item.id === event.active.id);
            const overItem = newAgendaItems.find(item => item.id === event.over!.id);

            if (activeItem) {
                const column = targetColumn ? targetColumn : event.over!.id;
                const columnItems = agendaItemsMapByColumn[column];

                if (overItem) {
                    // https://github.com/clauderic/dnd-kit/issues/1450
                    if (event.delta.y < 0) {
                        activeItem.order = insertBefore(columnItems, overItem, event.active.id);
                    } else {
                        activeItem.order = insertAfter(columnItems, overItem, event.active.id);
                    }
                } else {
                    activeItem.order = 0;
                }

                activeItem.column = column;
            }

            return newAgendaItems;
        });
    }

    return (
        <DndContext
            onDragStart={onDragStart} onDragOver={onDragOver} onDragEnd={onDragEnd}
            sensors={sensors}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
            collisionDetection={closestCorners}
        >
            <Row gutter={16}>
                {props.meetingType.columns.map(((column, index) => (
                    <Col key={column} span={Math.floor(24 / props.meetingType.columns.length)}>
                        <TeamMeetingColumn
                            id={column}
                            name={column}
                            meeting={props.meeting}
                            agendaItems={agendaItemsMapByColumn[column] || []}
                        />
                    </Col>
                )))}
            </Row>
            <DragOverlay>
                {activeId ? <AgendaItemCard agendaItem={agendaItemsMapById[activeId]} /> : null}
            </DragOverlay>
        </DndContext>
    );
}

export default TeamMeetingDraggableContainer;
