import type {AgendaItem} from 'types';
import {useContext} from 'react';


import {TeamMeetingContext, type TeamMeetingContextProps} from 'providers/TeamMeetingProvider';

export function useTeamMeetings() {
    const context = useContext(TeamMeetingContext);
    return context.meetings;
}

export function useTeamMeetingState(): [TeamMeetingContextProps['meetings'], TeamMeetingContextProps['setTeamMeetings']] {
    const context = useContext(TeamMeetingContext);
    return [context.meetings, context.setTeamMeetings];
}

export function useAgendaItems(meetingId: string) {
    const context = useContext(TeamMeetingContext);
    return context.agendaItems.filter(agendaItem => agendaItem.meeting.id === meetingId) || [];
}

export function useAddAgendaItem() {
    const context = useContext(TeamMeetingContext);
    return (agendaItem: AgendaItem) => {
        context.setAgendaItems(agendaItems => {
            return agendaItems ? [...agendaItems, agendaItem] : [agendaItem];
        });
    }
}

export function useSetAgendaItems() {
    const context = useContext(TeamMeetingContext);
    return context.setAgendaItems;
}
