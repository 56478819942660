import {
    CreateObject,
    Template
} from 'types';
import type {CreateStepProps} from './CreateEditSteps';
import TextFieldStep from './TextFieldStep';
import SingleSelectFieldStep from './SingleSelectFieldStep';
import MultiSelectFieldStep from './MultiSelectFieldStep';
import OneToOneFieldStep from './OneToOneFieldStep';
import OneToManyFieldStep from './OneToManyFieldStep';
import React from 'react';

function templateSteps(
    obj: CreateObject,
    setObj: (obj: any) => void,
    template: Template|undefined,
    steps: CreateStepProps[]
) {
    if (template) {
        const newSteps = [...steps];
        for (let i = 0; i < template.fields.length; i++) {
            const field = template.fields[i];
            switch (field.type) {
                case 'text':
                    newSteps.push({
                        title: field.name,
                        content:
                            <TextFieldStep
                                field={field}
                                value={obj[field.name]}
                                setValue={value => {
                                    setObj({...obj, [field.name]: value})
                                }}
                            />,
                        valid: () => (!field.required || !!obj[field.name])
                    });
                    break;
                case 'single-select':
                    newSteps.push({
                        title: field.name,
                        content:
                            <SingleSelectFieldStep
                                field={field}
                                value={obj[field.name]}
                                setValue={value => {
                                    setObj({...obj, [field.name]: value})
                                }}
                            />,
                        valid: () => (!field.required || !!obj[field.name])
                    });
                    break;
                case 'multi-select':
                    newSteps.push({
                        title: field.name,
                        content:
                            <MultiSelectFieldStep
                                field={field}
                                values={obj[field.name] || []}
                                setValues={values => {
                                    setObj({...obj, [field.name]: values})
                                }}
                            />,
                        valid: () =>  (!field.required || (obj[field.name] && obj[field.name].length > 0))
                    });
                    break;
                case 'one-to-one':
                    newSteps.push({
                        title: field.name,
                        content:
                            <OneToOneFieldStep
                                field={field}
                                objectRef={obj[field.name]}
                                setObjectRef={ref => {
                                    setObj({...obj, [field.name]: ref})
                                }}
                            />,
                        valid: () => (!field.required || obj[field.name]?.id)
                    });
                    break;
                case 'one-to-many':
                    newSteps.push({
                        title: field.name,
                        content:
                            <OneToManyFieldStep
                                field={field}
                                objectRefs={obj[field.name]}
                                setObjectRefs={refs => {
                                    setObj({...obj, [field.name]: refs})
                                }}
                            />,
                        valid: () => (!field.required || (obj[field.name] && obj[field.name].length > 0))
                    });
                    break;
            }
        }
        return newSteps;
    }
    return steps;
}

export default templateSteps;
