import {Space} from 'antd';
import ResetButton from './ResetButton';

import styles from './styles.module.scss';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';

const Controls = (props: {
    onZoomIn: () => void,
    onZoomOut: () => void,
    onReset: () => void,
    resetDisabled?: boolean
}) => {
    return (
        <Space size={4} direction={'vertical'} className={styles.controls}>
            <ResetButton onClick={props.onReset} disabled={props.resetDisabled}/>
            <ZoomInButton onClick={props.onZoomIn} />
            <ZoomOutButton onClick={props.onZoomOut} />
        </Space>
    );
}

export default Controls;
