import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {
    CustomerServiceOutlined,
    IdcardOutlined,
    LikeOutlined,
    TrophyOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {MenuItem, SiderMenuProps} from './types';


const TablesMenu = (props: SiderMenuProps) => {

    const menuItems = [{
        key: 'tables',
        label: <span>Tables</span>,
        type: 'group',
        children: [
            {
                key: 'people',
                label: <Link to="/people">People</Link>,
                icon: <UserOutlined/>
            },
            {
                key: 'roles',
                label: <Link to="/roles">Roles</Link>,
                icon: <IdcardOutlined/>
            },
            {
                key: 'goals',
                label: <Link to="/goals">Goals</Link>,
                icon: <TrophyOutlined/>
            },
            {
                key: 'decisions',
                label: <Link to="/decisions">Decisions</Link>,
                icon: <LikeOutlined/>
            },
            {
                key: 'service-agreements',
                label: <Link to="/service-agreements">Service Agreements</Link>,
                icon: <CustomerServiceOutlined/>
            }
        ]
    }] as MenuItem[];


    return (
        <Menu
            mode="inline"
            items={menuItems}
            selectedKeys={props.selectedKeys}
            onSelect={props.onSelect}
        />
    );
}

export default TablesMenu;
