import {Button, ButtonProps} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

const AddButton = (props: ButtonProps) => {
    const {type = 'primary', icon = <PlusOutlined />, children = 'Add', ...restProps} = props;
    return (
        <Button type={type} icon={icon} {...restProps} >{children}</Button>
    );
}

export default AddButton;
