import {useState} from 'react';
import {PayloadAction} from '@reduxjs/toolkit';
import {App} from 'antd';

import {Team, Zone, PersonRef, ErrorDetail} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {map} from 'utils';

import {useCurrentUser} from 'hooks/useCurrentUser';
import {useTemplates} from 'hooks/useTemplates';

import CreateEditSteps from 'components/steps/_common/CreateEditSteps';
import NameStep from 'components/steps/_common/NameStep';
import ParentZoneStep from 'components/steps/_common/ParentZoneStep';
import TemplateStep from 'components/steps/_common/TemplateStep';
import templateSteps from 'components/steps/_common/templateSteps';

import {createTeam} from 'features/teamSlice';
import PurposeStep from './PurposeStep';

const CreateTeamSteps = (props: {
    zone?: Zone,
    onSuccess: (team: Team) => void,
    onCancel: () => void,
}) => {
    const {message} = App.useApp();
    const currentUser = useCurrentUser();
    const [team, setTeam] = useState({} as Team);
    const dispatch: AppDispatch = useAppDispatch();

    const templates = map(useTemplates('team'));
    const template = team.template?.id ? templates[team.template.id] : undefined;

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setTeam({} as Team);
        }, 0);
    }

    async function onCreate() {
        const newTeam = {
            ...team,
            owners: [{id: currentUser?.id, type: 'person'} as PersonRef],
        };
        if (props.zone) {
            newTeam.zone = {id: props.zone.id, type: 'zone'}
        }
        const res = await dispatch(createTeam(newTeam as Team) as any) as PayloadAction<Team | ErrorDetail, any, any, string>;
        if (res.error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        props.onSuccess(res.payload as Team);
        message.success('Your team was created successfully.')
    }

    const steps = [
        {
            title: 'Team Template',
            content:
                <TemplateStep
                    objectType={'team'}
                    templateId={team.template?.id}
                    setTemplate={(templateId: string) => {
                        setTeam({...team, template: {type: 'template', id: templateId}});
                    }}
                />,
            valid: () => !!team.template?.id,
        },
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this team be called?"
                    name={team.name || ''}
                    setName={name => {
                        setTeam({...team, name});
                    }}
                />,
            valid: () => !!team.name,
        },
        {
            title: 'Purpose',
            content:
                <PurposeStep
                    purpose={team?.purpose || ''}
                    setPurpose={purpose => {
                        setTeam({...team, purpose});
                    }}
                />,
            valid: () => !!team.purpose,
        }
    ];

    if (!props.zone) {
        steps.push(
            {
                title: 'Parent Zone',
                content:
                    <ParentZoneStep
                        parentZone={team.zone?.id || ''}
                        setParentZone={(zoneId: string) => {
                            setTeam({
                                ...team,
                                zone: {id: zoneId, type: 'zone'}
                            });
                        }}
                    />,
                valid: () => !!team.zone?.id,
            },
        )
    }

    return (
        <>
            <CreateEditSteps
                steps={templateSteps(team, setTeam, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateTeamSteps;
