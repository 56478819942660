import React from 'react';
import {Row, Col} from 'antd';

import OrgCard from './org/OrgCard';
import TemplatesCard from './templates/TemplatesCard';
import MeetingTypesCard from './meetings/MeetingTypesCard';

const SettingsPage = () => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <OrgCard/>
            </Col>
            <Col span={24}>
                <TemplatesCard />
            </Col>
            <Col span={24}>
                <MeetingTypesCard id={'meeting-types'}/>
            </Col>
        </Row>
    );
}

export default SettingsPage;
