export function defaultRadius(width: number, nodeCount: number, radius?: number) {
    //return radius ? radius : width / Math.max(4*nodeCount, 1);
    return 20;
}

export function canvasToElement(canvas: HTMLCanvasElement, canvasX: number, canvasY: number): [number, number]  {
    const rect = canvas.getBoundingClientRect();

    const x = canvasX / canvas.width * rect.width;
    const y = canvasY / canvas.height * rect.height;

    return [x, y];
}
