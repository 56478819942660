import type {HierarchyCircularNode, ZoomView} from 'd3';
import type {CPGNode} from './types';
import {generateColor} from 'utils';
import {Size} from '@custom-react-hooks/use-element-size';

export function buildColorMap(root: HierarchyCircularNode<CPGNode>, count: number) {
    const colorMap = new Map<string, HierarchyCircularNode<CPGNode>>();

    root
        .descendants()
        .slice(1)
        //.filter(node => node.data.obj.type === 'zone')
        .map((node, index) => {
            const uniqueColor = generateColor(index, count);
            if (colorMap.get(uniqueColor)) {
                console.log(uniqueColor);
            }
            return colorMap.set(uniqueColor, node);
        });

    return colorMap;
}

export async function loadImages(root: HierarchyCircularNode<CPGNode>) {
    const images = root
        .descendants()
        .slice(1)
        .filter(node => !!node.data.src)
        .map(node => {
            return new Promise<HTMLImageElement>(r => {
                const img = new Image();
                img.onload = (() => r(img));
                img.src = node.data.src!;
            });
        });
    return Promise.all(images);
}

/* Find the first focusable parent node */
export function getFocusableNode(node: HierarchyCircularNode<CPGNode>|null|undefined) {
    while (node) {
        if (!node.data.notFocusable) {
            return node;
        }
        node = node.parent;
    }
    return node;
}

export function nodeToZoomView(node: HierarchyCircularNode<CPGNode>): ZoomView {
    return [node.x, node.y, 2 * node.r] as ZoomView;
}

export function zoomViewEquals(a: ZoomView, b: ZoomView) {
    for (let i = 0; i < 3; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}

export function dimension(size: Size) {
    return Math.min(size.width, size.height)
}

export function canvasScaleFactor(size: Size, canvas_dimension: number) {
    const dimension = Math.min(size.width, size.height);
    return dimension ? Math.ceil(canvas_dimension / dimension) : 0;
}

/*
 * The minimum viewport size is the minimum diameter of a non-leaf node.
 */
export function minZoomDimension(rootNode: HierarchyCircularNode<CPGNode>) {
    let radius = rootNode.r;
    rootNode
        .descendants()
        .map(node => {
            if (!node.children || node.children.length === 0) {
                if (node.r < radius) {
                    radius = node.r;
                }
            }
            return node.r;
        });
    return radius * 2;
}
