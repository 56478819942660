import React, {useState} from 'react';
import {Button} from 'antd';

import type {Meeting, Team} from 'types';
import CreateEditModal from 'components/steps/_common/CreateEditModal';
import CreateEditMeetingSteps from 'components/steps/meetings/CreateEditMeetingSteps';

const AddTeamMeetingButton = (props: {
    team: Team,
    onCreate?: (meeting: Meeting) => void
}) => {
    const [openModal, setOpenModal] = useState(false);

    const onCancel = () => setOpenModal(false);
    const onCreate = (meeting: Meeting) => {
        props.onCreate?.(meeting);
        setOpenModal(false)
    }

    return (
        <>
            <Button type={'primary'} size={'small'} onClick={() => setOpenModal(true)}>Add Meeting</Button>
            <CreateEditModal
                title={'Create a team meeting'}
                open={openModal}
                onCancel={onCancel}
            >
                <CreateEditMeetingSteps
                    team={props.team}
                    onSuccess={onCreate}
                    onCancel={onCancel}
                />
            </CreateEditModal>
        </>
    );
}

export default AddTeamMeetingButton;
