import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {App, Form, Input, Select, Row, Col} from 'antd';

import type {ErrorDetail, ErrorDetailDispatch, Template, Field} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {updateTemplate} from 'features/templateSlice';
import {useTemplates} from 'hooks/useTemplates';

import SettingsCreateEditPage from 'pages/settings/_common/SettingsCreateEditPage';
import SettingsButtonBar from 'pages/settings/_common/SettingsButtonBar';
import SettingsSectionTitle from 'pages/settings/_common/SettingsSectionTitle';

import FieldsTable from './edit/FieldsTable';
import {map} from 'utils';


type FormFieldsType = {
    name?: string,
    description?: string,
    objectType?: Template['objectType']
};

function defaultFields(fields: Field[] | undefined) {
    if (fields) {
        return fields.map(field => ({...field}));
    }
    return []
}

const EditTemplatePage = () => {
    let { templateId } = useParams();
    const templates = map(useTemplates());
    const template = templateId ? templates[templateId] : undefined;

    const navigate = useNavigate();
    const {message} = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();
    const [fields, setFields] = useState<Field[]>();

    useEffect(() => {
        if (!fields && template) {
            setFields(defaultFields(template.fields));
        }
    }, [fields, template]);

    if (!template || !fields) {
        return null;
    }

    async function onFinish(values: FormFieldsType) {
        const newTemplate = {...values, fields: fields as Field[], id: templateId} as Template;

        const res = await dispatch(updateTemplate(newTemplate));
        if ((res as ErrorDetailDispatch<Template>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        navigate(`/settings/templates/${templateId}`);
    }

    return (
        <SettingsCreateEditPage
            description={'A template allows you to define the information collected for objects in this organization.'}
        >
            <Form
                name="template"
                initialValues={{
                    name: template.name,
                    objectType: template.objectType,
                    description: template.description,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FormFieldsType>
                            label="Name"
                            name="name"
                            className='formControl'
                            rules={[{required: true, message: 'Please input a name!'}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FormFieldsType>
                            label="Object"
                            name="objectType"
                            className='formControl'
                            rules={[{required: true, message: 'Select a template type.'}]}
                        >
                            <Select
                                options={[
                                    {value: 'decision', label: 'Decision'},
                                    {value: 'goal', label: 'Goal'},
                                    {value: 'role', label: 'Role'},
                                    {value: 'team', label: 'Team'},
                                    {value: 'zone', label: 'Zone'},
                                ]}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item<FormFieldsType>
                            label="Description"
                            name="description"
                            className='formControl'
                            help={'Markdown is allowed.'}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                </Row>

                <div>
                    <SettingsSectionTitle>Fields</SettingsSectionTitle>
                    <FieldsTable fields={fields} setFields={setFields}/>
                </div>


                <SettingsButtonBar />
            </Form>
        </SettingsCreateEditPage>
    );
}

export default EditTemplatePage;
