import React from 'react';
import {Form, Select} from 'antd';

import {selectOptions} from 'utils';
import {useRoles} from 'hooks/useRoles';

const AccountableToFormItem = (props: {
    roleId: string | undefined,
    setRoleId: (role: string|undefined) => void;
}) => {
    const roles = useRoles();
    const title = 'Is this role accountable to another role?';

    return (
        <Form.Item
            label={title}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
        >
            <Select
                placeholder="Please select"
                defaultValue={props.roleId}
                onChange={props.setRoleId}
                options={selectOptions(roles)}
                allowClear={true}
            />
        </Form.Item>
    )
}

export default AccountableToFormItem;
