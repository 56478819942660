import styles from './styles.module.scss';
import type {Meeting, Team} from 'types';
import DeleteTeamMeetingButton from './DeleteTeamMeetingButton';
import EditTeamMeetingButton from './EditTeamMeetingButton';

const TeamMeetingTableButtons = (props: {
    team: Team,
    meeting: Meeting,
    onEdit?: (meeting: Meeting) => void,
    onDelete?: (meeting: Meeting) => void,
}) => {
    return (
        <div className={styles.buttons}>
            <EditTeamMeetingButton team={props.team} meeting={props.meeting} onConfirm={props.onEdit}/>
            <DeleteTeamMeetingButton meeting={props.meeting} onDelete={props.onDelete}/>
        </div>
    );
}

export default TeamMeetingTableButtons;
