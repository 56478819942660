import {CSSProperties} from 'react';

import styles from './styles.module.scss';

import {useThemeMode} from 'hooks/useThemeMode';

const BaseTooltip = (props: {
    canvas: HTMLCanvasElement | null,
    text: string | null | undefined;
    x: number,  // elementX
    y: number,  // elementY
}) => {
    const mode = useThemeMode();
    if (!props.text || !props.canvas) return null;

    const yOffset = 5 /* carat */ + 2 /* lineWidth/2 */;

    const style = {
        left: props.x,
        top: props.y,
        transform: `translate(-50%, -100%) translateY(-${yOffset}px)`,
    } as CSSProperties;

    return (
        <div className={`${styles.tooltip} ${mode === 'dark' ? styles.dark : undefined}`} style={style}>
            {props.text}
        </div>
    );
}

export default BaseTooltip;
