import React, {CSSProperties, useState} from 'react';
import {Button, App} from 'antd';

import {isPersonAdmin} from 'utils';
import CreateEditModal from 'components/steps/_common/CreateEditModal';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';
import {useCurrentUser} from 'hooks/useCurrentUser';

const CreateZoneButton = (props: {
    style?: CSSProperties
}) => {
    const currentUser = useCurrentUser();
    const {message} = App.useApp();
    const [openModal, setOpenModal] = useState(false);

    async function onSuccess() {
        setOpenModal(false);
        return message.success('Your zone was created successfully.');
    }

    function onClose() {
        setOpenModal(false);
    }

    if (!isPersonAdmin(currentUser)) {
        return null;
    }

    return (
        <>
            <Button
                style={props.style}
                onClick={() => setOpenModal(true)}
            >
                Create a zone
            </Button>
            <CreateEditModal
                title={'Create a zone'}
                open={openModal}
                onCancel={onClose}
            >
                <CreateZoneSteps onSuccess={onSuccess} onCancel={onClose} />
            </CreateEditModal>
        </>
    );
}

export default CreateZoneButton;
