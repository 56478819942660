import {useState} from 'react';
import {Card, Space} from 'antd';

import type {Meeting, NonEmptyArray, Team} from 'types';
import {useMeetingType} from 'hooks/useMeetingTypes';
import {getDefaultTeamMeeting, setDefaultTeamMeeting} from './utils';
import TeamMeetingCardHeader from './TeamMeetingsCardHeader';
import TeamMeetingDraggableContainer from './TeamMeetingDraggableContainer';

const TeamMeetingsCard = (props: {
    team: Team,
    meetings: NonEmptyArray<Meeting>
}) => {
    const [meeting, setMeeting] = useState(() => {
        const meetingId = getDefaultTeamMeeting(props.team.id);
        if (meetingId) {
            const meeting = props.meetings.find(m => m.id === meetingId);
            if (meeting) {
                return meeting;
            }
        }
        return props.meetings.length ? props.meetings[0] : undefined
    });
    const meetingType = useMeetingType(meeting?.meeting_type.id);
    if (!meeting || !meetingType) return null;

    const onSetMeeting = (meeting: Meeting) => {
        setMeeting(meeting);
        setDefaultTeamMeeting(props.team.id, meeting.id);
    }

    return (
        <Card>
            <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
                <TeamMeetingCardHeader meeting={meeting} setMeeting={onSetMeeting} meetings={props.meetings}/>
                <TeamMeetingDraggableContainer meeting={meeting} meetingType={meetingType}/>
            </Space>
        </Card>
    );
}

export default TeamMeetingsCard;
