import {Space, Typography} from 'antd';
import {IdcardOutlined} from '@ant-design/icons';

import type {Role} from 'types';
import {useRole} from 'hooks/useRoles';
import DrawerDescription from 'components/drawers/common/DrawerDescription';

const RoleAccountableTo = (props: {
    role: Role,
}) => {
    const accountableTo = useRole(props.role.accountable_to?.id);
    if (!accountableTo) return null;

    return (
        <DrawerDescription title={'Accountable To'}>
            <Typography.Link href={`/roles/${accountableTo.id}`}>
                <Space><IdcardOutlined/>{accountableTo.name}</Space>
            </Typography.Link>
        </DrawerDescription>
    );
}

export default RoleAccountableTo;
