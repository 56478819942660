import {Navigate, RouteObject} from 'react-router-dom';

import {current} from './loader';
import ErrorBoundary from 'components/ErrorBoundary';
import MainPage from 'components/layout/MainPage';

import LoginPage from 'pages/auth/login/LoginPage';
import ForgotPasswordPage from 'pages/auth/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';

import HomePage from 'pages/home/HomePage';
import ZoneChartPage from 'pages/zones/ZoneChartPage';

import PeopleListPage from 'pages/tables/people/PeopleListPage';
import PersonDetailPage from 'pages/tables/people/PersonDetailPage';

import ZoneDetailPage from 'pages/tables/zones/ZoneDetailPage';
import ZonesListPage from 'pages/tables/zones/ZonesListPage';

import TeamsListPage from 'pages/teams/TeamsListPage';
import TeamDetailPage from 'pages/teams/TeamDetailPage';

import RolesListPage from 'pages/tables/roles/RolesListPage';
import RoleDetailPage from 'pages/tables/roles/RoleDetailPage';

import GoalsListPage from 'pages/tables/goals/GoalsListPage';
import GoalDetailPage from 'pages/tables/goals/GoalDetailPage';

import DecisionDetailPage from 'pages/tables/decisions/DecisionDetailPage';
import DecisionsListPage from 'pages/tables/decisions/DecisionsListPage';

import ServiceAgreementDetailPage from 'pages/tables/serviceAgreements/ServiceAgreementDetailPage';
import ServiceAgreementsListPage from 'pages/tables/serviceAgreements/ServiceAgreementsListPage';

import SettingsPage from 'pages/settings/SettingsPage';
import TemplateDetailPage from 'pages/settings/templates/TemplateDetailPage';
import CreateTemplatePage from 'pages/settings/templates/CreateTemplatePage';
import EditTemplatePage from 'pages/settings/templates/EditTemplatePage';
import CreateEditMeetingTypePage from 'pages/settings/meetings/CreateEditMeetingTypePage';

import AdminPage from 'pages/admin/AdminPage';

import ProfilePage from 'pages/profile/ProfilePage';
import OrganizationPage from 'pages/org/OrganizationPage';
import EditMeetingTypePage from 'pages/settings/meetings/EditMeetingTypePage';

const router = [
    {
        path: '/login',
        element: <LoginPage/>,
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: '',
        element: <MainPage/>,
        errorElement: <ErrorBoundary/>,
        loader: current,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/zoneChart',
                element: <ZoneChartPage/>,
                handle: {
                    title: 'Zone Chart',
                },
            },
            {
                path: '/orgChart',
                element: <OrganizationPage/>,
                handle: {
                    title: 'Organization',
                },
            },
            {
                path: '/profile',
                element: <ProfilePage/>,
                handle: {
                    title: 'Profile',
                },
            },
            {
                path: '/people',
                children: [
                    {
                        path: '',
                        element: <PeopleListPage/>,
                        handle: {
                            title: 'People',
                        },
                    },
                    {
                        path: ':personId',
                        element: <PersonDetailPage/>
                    }
                ]
            },

            {
                path: '/zones',
                children: [
                    {
                        path: '',
                        element: <ZonesListPage/>,
                        handle: {
                            title: 'Zones',
                        },
                    },
                    {
                        path: ':zoneId',
                        element: <ZoneDetailPage/>
                    }
                ]
            },

            {
                path: '/teams',
                children: [
                    {
                        path: '',
                        element: <TeamsListPage/>,
                        handle: {
                            title: 'Teams',
                        },
                    },
                    {
                        path: ':teamId',
                        element: <TeamDetailPage/>
                    }
                ]
            },

            {
                path: '/roles',
                children: [
                    {
                        path: '',
                        element: <RolesListPage/>,
                        handle: {
                            title: 'Roles',
                        },
                    },
                    {
                        path: ':roleId',
                        element: <RoleDetailPage/>
                    }
                ]
            },


            {
                path: '/goals',
                children: [
                    {
                        path: '',
                        element: <GoalsListPage/>,
                        handle: {
                            title: 'Goals',
                        },
                    },
                    {
                        path: ':goalId',
                        element: <GoalDetailPage/>
                    }
                ]
            },

            {
                path: '/decisions',
                children: [
                    {
                        path: '',
                        element: <DecisionsListPage/>,
                        handle: {
                            title: 'Decisions',
                        },
                    },
                    {
                        path: ':decisionId',
                        element: <DecisionDetailPage/>
                    }
                ]
            },

            {
                path: '/service-agreements',
                children: [
                    {
                        path: '',
                        element: <ServiceAgreementsListPage/>,
                        handle: {
                            title: 'Service Agreements',
                        },
                    },
                    {
                        path: ':serviceAgreementId',
                        element: <ServiceAgreementDetailPage/>
                    }
                ]
            },

            {
                path: '/admin',
                element: <AdminPage />
            },

            {
                path: '/settings',
                children: [
                    {
                        path: '',
                        element: <SettingsPage/>,
                        handle: {
                            title: 'Settings',
                        },
                    },
                    {
                        path: 'create-template',
                        element: <CreateTemplatePage/>,
                        handle: {
                            title: 'Create A New Template',
                        },
                    },
                    {
                        path: '/settings/templates/:templateId',
                        element: <TemplateDetailPage/>
                    },
                    {
                        path: '/settings/templates/:templateId/edit',
                        element: <EditTemplatePage/>
                    },
                    {
                        path: 'create-meeting-type',
                        element: <CreateEditMeetingTypePage/>,
                        handle: {
                            title: 'Create A New Meeting Type',
                        }
                    },
                    {
                        path: '/settings/meeting-types/:meetingTypeId',
                        element: <EditMeetingTypePage/>
                    },
                ]
            },

            {
                path: '*',
                element: <Navigate to={'/zoneChart'} replace/>,
            }
        ]
    }
] as RouteObject[]

export default router;
