import {SearchResult} from 'types';

const SearchLabel = (props: {
    result: SearchResult
}) => {
    return (
        <div>
            {props.result.name}
        </div>
    );
}

export default SearchLabel;
