import {FDGNode} from './types';

import BaseTooltip from 'components/canvas/common/tooltip/BaseTooltip';
import {canvasToElement} from './utils';

const FDGTooltip = (props: {
    canvas: HTMLCanvasElement | null,
    node: FDGNode|undefined,
    onTooltipText: (node: FDGNode|undefined) => string|null|undefined;
}) => {
    if (!props.canvas || !props.node || !props.node.x || !props.node.y || !props.node.radius) return null;

    const p = canvasToElement(props.canvas, props.node.x, props.node.y - props.node.radius);
    const text = props.onTooltipText(props.node);
    if (!text) return null;

    return (
        <BaseTooltip
            canvas={props.canvas}
            text={props.onTooltipText(props.node)}
            x={p[0]} y={p[1]}
        />
    );
}

export default FDGTooltip;
