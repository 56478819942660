import {Link} from 'react-router-dom';
import {Card, Table, Typography} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import {asDataSource} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import CreateTemplateButton from './CreateTemplateButton';


const TemplatesCard = () => {
    const templates = useTemplates();

    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/settings/templates/${id}`}><span className={'text-nowrap'}>{text}</span></Link>
        },
        {
            title: 'Type',
            dataIndex: 'objectType',
            key: 'objectType',
            render: text => text
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => text
        }
    ];

    return (
        <Card>
            <Typography.Title level={2}>Templates</Typography.Title>
            <Table
                columns={columns}
                dataSource={asDataSource(templates)}
                pagination={false}
            />
            <CreateTemplateButton />
        </Card>
    );
}

export default TemplatesCard;
