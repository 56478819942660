import {Space, Table} from 'antd';

import {Meeting, Team} from 'types';
import {asDataSource, isUndefined} from 'utils';
import {useOrg} from 'hooks/useOrg';

import styles from './styles.module.scss';
import {useTeamMeetingState} from 'hooks/meetings';
import AddTeamMeetingButton from './AddTeamMeetingButton';
import TeamMeetingTableButtons from './TeamMeetingTableButtons';

const TeamMeetingsTable = (props: {
    team: Team
}) => {
    const org = useOrg();
    const [meetings, setMeetings] = useTeamMeetingState();

    if (!org || isUndefined(meetings)) return null;

    const onCreate = (meeting: Meeting) => {
        setMeetings(meetings => {
            return meetings ? [...meetings, meeting] : [meeting];
        });
    }

    const onEdit = (meeting: Meeting) => {
        setMeetings(meetings => {
            const newMeetings = meetings ? [...meetings, meeting] : [meeting];
            const index = newMeetings?.findIndex(m => m.id === meeting.id);
            newMeetings[index] = meeting;
            return newMeetings;
        });
    }

    const onDelete = (meeting: Meeting) => {
        setMeetings(meetings => {
            return meetings ? meetings?.filter(m => m.id !== meeting.id) : [];
        });
    }

    const columns = [
        {
            title: 'Team Meetings',
            dataIndex: 'name',
            key: 'name',
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            width: '1%',
            render: (_: any, meeting: Meeting) => (
                <TeamMeetingTableButtons
                    team={props.team}
                    meeting={meeting}
                    onEdit={onEdit} onDelete={onDelete}
                />
            )
        },
    ];

    return (
        <Space direction={'vertical'} style={{ width: '100%' }} size={'middle'}>
            <Table className={styles.table} dataSource={asDataSource<Meeting>(meetings)} columns={columns} pagination={false}/>
            <AddTeamMeetingButton team={props.team} onCreate={onCreate}/>
        </Space>
    );
}

export default TeamMeetingsTable;
