import {useState} from 'react';
import {App} from 'antd';

import {Goal, Team, TemplateRef, ErrorDetailDispatch, ErrorDetail} from 'types';
import {AppDispatch, useAppDispatch} from 'store';

import CreateEditSteps from 'components/steps/_common/CreateEditSteps';
import DescriptionStep from 'components/steps/_common/DescriptionStep';
import NameStep from 'components/steps/_common/NameStep';
import TeamStep from 'components/steps/_common/TeamStep';
import TemplateStep from 'components/steps/_common/TemplateStep';

import {createGoal} from 'features/goalSlice';
import templateSteps from '../_common/templateSteps';
import {map} from '../../../utils';
import {useTemplates} from '../../../hooks/useTemplates';

const CreateGoalSteps = (props: {
    team?: Team,
    onSuccess: (goal: Goal) => void
    onCancel: () => void
}) => {
    const {message} = App.useApp();

    const [goal, setGoal] = useState({} as Goal);
    const dispatch: AppDispatch = useAppDispatch();

    const templates = map(useTemplates());
    const template = goal.template?.id ? templates[goal.template.id] : undefined;


    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setGoal({} as Goal);
        }, 0);
    }

    async function onCreate() {
        const newGoal = {
            ...goal
        };
        if (props.team) {
            newGoal.team = {id: props.team.id, type: 'team'}
        }

        const res = await dispatch(createGoal(newGoal as Goal));
        if ((res as ErrorDetailDispatch<Goal>).error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        message.success('Your goal was successfully created.')
        props.onSuccess(res.payload as Goal);
    }

    const steps = [
        {
            title: 'Goal Template',
            content:
                <TemplateStep
                    objectType={'goal'}
                    templateId={goal.template?.id || ''}
                    setTemplate={(templateId: string) => {
                        const newRole = {
                            ...goal, template: {type: 'template', id: templateId} as TemplateRef
                        };
                        setGoal(newRole);
                    }}
                />,
            valid: () => true,
        }
    ];

    if (!props.team) {
        steps.push(
            {
                title: 'Team',
                content:
                    <TeamStep
                        title="What team is setting this goal?"
                        teamId={goal.team?.id || ''}
                        setTeamId={teamId => {
                            const newGoal = {...goal, team: {id: teamId, type: 'team'}} as Goal;
                            setGoal(newGoal);
                        }}
                    />,
                valid: () => !!goal.team?.id
            }
        )
    }

    steps.push(
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this goal be called?"
                    name={goal.name || ''}
                    setName={name => {
                        setGoal({...goal, name});
                    }}
                />,
            valid: () => !!goal.name,
        },
        {
            title: 'Description',
            content:
                <DescriptionStep
                    name={'goal'}
                    description={goal.description || ''}
                    setDescription={description => {
                        setGoal({...goal, description});
                    }}
                />,
            valid: () => !!goal.description,
        }
    )

    return (
        <>
            <CreateEditSteps
                steps={templateSteps(goal, setGoal, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateGoalSteps;
