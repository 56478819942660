import type {Organization, Person, Role, Team, Zone} from '../../types';
import type {CPGNode} from '../../components/canvas/cpg/types';
import styles from './styles.module.scss';


function data(org: Organization, zones: Zone[], zoneFilters: string[], teams: Team[], roles: Role[], people: Record<string, Person>): {root: CPGNode, count: number} {
    const graph: Record<string, CPGNode> = {}

    for (const zone of zones) {
        graph[zone.id] = {
            id: zone.id,
            title: zone.name,
            obj: zone,
            children: [],
            parentId: null,
            value: 2,
            strokeStyle: styles.zoneColorStroke,
            fillStyle: styles.zoneColorFill,
        }
    }

    for (const zone of zones) {
        if (zone.parentZone?.id) {
            graph[zone.parentZone?.id].children.push(graph[zone.id]);
            graph[zone.id].parentId = zone.parentZone?.id || null;
        }
    }

    for (const team of teams) {
        graph[team.id] = {
            id: team.id,
            title: team.name,
            obj: team,
            children: [],
            parentId: null,
            value: 2,
            strokeStyle: styles.teamColorStroke,
            fillStyle: styles.teamColorFill,
        }
    }

    for (const team of teams) {
        if (team.zone?.id) {
            graph[team.zone?.id].children.push(graph[team.id]);
            graph[team.id].parentId = team.zone?.id || null;
        }
    }

    for (const role of roles) {
        const user = role.assignment?.id ? people[role.assignment.id] : undefined;
        graph[role.team.id].children.push({
            id: role.id,
            title: role.name,
            obj: role,
            value: 1,
            children: [],
            parentId: role.team.id,
            strokeStyle: styles.roleColorStroke,
            fillStyle: role.assignment ? styles.roleColorFill : 'transparent',
            src: user?.picture_large,
            notFocusable: true
        });
    }

    const root = {
        obj: org,
        children: [] as CPGNode[],
        parentId: null,
    } as CPGNode

    for (const zone of zones) {
        if (graph[zone.id].parentId === null && !zoneFilters.includes(zone.id)) {
            graph[zone.id].parentId = org.id;
            root.children.push(graph[zone.id]);
        }
    }
    return {root, count: Object.keys(graph).length + roles.length};
}

export default data;
