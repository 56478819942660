import {Form, App} from 'antd';

import type {AgendaItem, Create, Meeting} from 'types';
import {getErrorMessage} from 'utils';
import NameFormItem from 'components/forms/common/NameFormItem';
import DescriptionFormItem from 'components/forms/common/DescriptionFormItem';
import FormButtonBar from 'components/forms/common/FormButtonBar';
import DeleteAgendaItemButton from 'components/buttons/DeleteAgendaItemButton';
import {createAgendaItem, updateAgendaItem} from 'services/meetings';
import {useOrg} from 'hooks/useOrg';

type AgendaItemFormFields = Pick<AgendaItem, 'name'|'description'>;

type AgendaItemFormBaseProps = {
    onFinish?: (agendaItem: AgendaItem) => void,
    onCancel?: () => void
}

type AgendaItemFormCreateProps = AgendaItemFormBaseProps & {
    meeting: Meeting,
    column: string,
    order: number,
}

type AgendaItemFormUpdateProps = AgendaItemFormBaseProps & {
    agendaItem: AgendaItem,
    onDelete?: (agendaItem: AgendaItem) => void
}

type AgendaItemFormProps = AgendaItemFormCreateProps | AgendaItemFormUpdateProps;

const AgendaItemForm = (props: AgendaItemFormProps) => {
    const {message} = App.useApp();

    const org = useOrg();
    if (!org) return null;

    const isEdit = !!(props as AgendaItemFormUpdateProps).agendaItem

    const onCreate = async (values: AgendaItemFormFields) => {
        const createProps = props as AgendaItemFormCreateProps;
        try {
            const createRequest: Create<AgendaItem> = {
                ...values,
                meeting: {'id': createProps.meeting.id, 'type': 'meeting'},
                team: createProps.meeting.team,
                order: createProps.order,
                column: createProps.column
            };
            const agendaItem = await createAgendaItem(org, createRequest);
            props.onFinish?.(agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    const onEdit = async (values: AgendaItemFormFields) => {
        const updateProps = props as AgendaItemFormUpdateProps;
        try {
            const agendaItem = await updateAgendaItem(org, {...updateProps.agendaItem, ...values} as AgendaItem);
            props.onFinish?.(agendaItem);
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    const onFinish = async (values: AgendaItemFormFields) => {
        return isEdit ? onEdit(values) : onCreate(values);
    }

    return (
        <Form
            layout={'vertical'}
            initialValues={(props as AgendaItemFormUpdateProps).agendaItem}
            onFinish={onFinish}
        >
            <NameFormItem />
            <DescriptionFormItem />

            <FormButtonBar
                onCancel={props.onCancel}
                extra={isEdit ?
                    <DeleteAgendaItemButton
                        agendaItem={(props as AgendaItemFormUpdateProps).agendaItem}
                        onDelete={(props as AgendaItemFormUpdateProps).onDelete}
                    >Mark Done</DeleteAgendaItemButton> : null
                }
            />
        </Form>
    );
}

export default AgendaItemForm;
