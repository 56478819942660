import {CSSProperties, useState} from 'react';
import {Card, Typography} from 'antd';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {AgendaItem} from 'types';
import AgendaItemForm from 'components/forms/AgendaItemForm';
import CreateEditModal from 'components/steps/_common/CreateEditModal';

import styles from './styles.module.scss';


const AgendaItemCard = (props: {
    agendaItem: AgendaItem
}) => {
    const [open, setOpen] = useState(false);

    const {isDragging, attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.agendaItem.id, data: {column: props.agendaItem.column}});

    const onCancel = () => setOpen(false);

    const onClick = () => setOpen(true);

    const onFinish = () => {
        setOpen(false);
    }

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        zIndex: isDragging ? 9999 : undefined,
        visibility: isDragging ? 'hidden' : 'visible'  // Show card only in DragOverlay
    }

    return (
        <>
            <Card
                style={style}
                ref={setNodeRef}
                {...listeners}
                {...attributes}
                className={`agenda-item ${styles.agendaItem}`}
                size={'small'}
                onClick={onClick}
            >
                <Typography.Text strong>{props.agendaItem.name}</Typography.Text>
            </Card>
            <CreateEditModal
                open={open}
                title={'Update Agenda Item'}
                onCancel={onCancel}
                width={500}
            >
                <AgendaItemForm {...props} onFinish={onFinish} onCancel={onCancel} />
            </CreateEditModal>
        </>
    );
}

export default AgendaItemCard;
