import EditableDnDTable, {DataType} from 'components/tables/editableDnD/EditableDnDTable';

const MeetingTypeColumnsTable = (props: {
    columnNames: string[],
    setColumnNames: (names: string[]) => void
}) => {
    const values = props.columnNames.map(name => ({name}));

    const setValues = (newValues: DataType[]) => {
        const columnNames = newValues.map(value => value['name']);
        props.setColumnNames(columnNames);
    }

    const columns = [{title: 'Name', dataIndex: 'name'}];

    return <EditableDnDTable
        nonEmpty uniqueOnly
        values={values} setValues={setValues}
        columns={columns}
    />
}

export default MeetingTypeColumnsTable;
