import {Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

const ZoomInButton = (props: {
    onClick: () => void,
    disabled?: boolean
}) => {
    return (
        <Button size={'small'} disabled={props.disabled} icon={<PlusOutlined />} onClick={props.onClick}/>
    );
}

export default ZoomInButton;
