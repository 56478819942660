import {SelectProps} from 'antd';
import {capitalCase, kebabCase} from 'change-case';

import {ObjectType, SearchResult} from 'types';
import SearchLabel from './SearchLabel';


const objectUrl = (type: ObjectType, id: string) => {
    const name = type === 'person' ? 'people' : `${kebabCase(type)}s`;
    return `/${name}/${id}`;
}

const resultsToOptionGroup = (type: ObjectType, results: SearchResult[]) => {
    return results.map(result => {
        return {
            value: objectUrl(type, result.id),
            label: <SearchLabel result={result} />
        }
    }) as SelectProps['options'];
}

export const resultsToOptions = (results: SearchResult[]) => {
    const map = new Map<string, SearchResult[]>();

    for (const result of results) {
        const values = map.get(result.type) || [];
        values.push(result);
        map.set(result.type, values);
    }

    const options = [];
    for (const type of Array.from(map.keys())) {
        options.push({
            label: <span>{capitalCase(type)}</span>,
            title: type,
            options: resultsToOptionGroup(type as ObjectType, map.get(type) || [])
        })
    }
    return options as SelectProps['options'];
}
