import {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Flex} from 'antd';

import styles from './styles.module.scss';

const SettingsButtonBar = (props: {
    onCancel?: () => void
    extraButtons?: ReactNode
}) => {
    const navigate = useNavigate();

    const onCancel = props.onCancel ? props.onCancel : () => navigate('/settings');
    return (
        <Flex justify={'space-between'} className={styles.btnBar}>
            <div>
                <Button type="default" style={{marginRight: '8px'}} onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </div>
            {props.extraButtons ? props.extraButtons : null}
        </Flex>
    );
}

export default SettingsButtonBar;
