import {createContext, PropsWithChildren, useEffect, useState} from 'react';

import type {AgendaItem, Meeting, Team} from 'types';
import {useOrg} from '../hooks/useOrg';
import {fetchAgendaItems, fetchMeetings} from '../services/meetings';
import {nameSort} from '../utils';

export type TeamMeetingContextProps = {
    meetings: Meeting[],
    agendaItems: AgendaItem[],
    setAgendaItems: (agendaItems: AgendaItem[] | ((agendaItems: AgendaItem[] | undefined) => AgendaItem[])) => void,
    setTeamMeetings: (teamMeetings: Meeting[] | ((teamMeeting: Meeting[] | undefined) => Meeting[])) => void,
};

export const TeamMeetingContext = createContext<TeamMeetingContextProps>({
    meetings: [],
    agendaItems: [],
    setAgendaItems: () => {},
    setTeamMeetings: () => {}
});

function useMeetingState(team: Team) {
    const org = useOrg();
    const [meetings, setTeamMeetings] = useState<Meeting[]>();
    const [agendaItems, setAgendaItems] = useState<AgendaItem[]>();

    useEffect(() => {
        if (!meetings && org) {
            fetchMeetings(org, team.id)
                .then(meetings => setTeamMeetings(meetings))
                .catch(e => console.log(e))
        }
    }, [meetings, org, team.id]);

    useEffect(() => {
        if (!agendaItems && org) {
            fetchAgendaItems(org, team.id)
                .then(agendaItems => setAgendaItems(agendaItems))
                .catch(e => console.log(e))
        }
    }, [agendaItems, org, team.id]);

    return {
        meetings: meetings ? [...meetings].sort(nameSort) : [],
        setTeamMeetings,
        // refreshMeetings: () => setMeetings(undefined),

        agendaItems: agendaItems || [],
        setAgendaItems,
        // refreshAgendaItems: () => setAgendaItems(undefined),
    };
}

const TeamMeetingProvider = (props: PropsWithChildren<{
    team: Team
}>) => {
    const {meetings, setTeamMeetings, agendaItems, setAgendaItems} = useMeetingState(props.team);
    return (
        <TeamMeetingContext.Provider value={{meetings, setTeamMeetings, agendaItems, setAgendaItems}}>
            {props.children}
        </TeamMeetingContext.Provider>
    );
}

export default TeamMeetingProvider;
