import {Button, Flex} from 'antd';
import {CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {DataType} from './EditableDnDTable';

const RowOps = (props: {
    record: DataType,
    editable: boolean,
    onEdit: (record: DataType) => void,
    onConfirm: (record: DataType) => void,
    onDelete?: (record: DataType) => void,
    onCancel: (record: DataType) => void,
}) => (
    <Flex justify={'end'} style={{whiteSpace: 'nowrap'}}>
        {props.editable ? (
            <>
                <Button type={'link'} icon={<CheckOutlined/>} onClick={() => props.onConfirm(props.record)}/>
                <Button type={'link'} icon={<CloseOutlined/>} onClick={() => props.onCancel(props.record)}/>
            </>
        ) : (
            <>
                <Button type={'link'} icon={<EditOutlined/>} onClick={() => props.onEdit(props.record)}/>
                <Button disabled={!props.onDelete} type={'link'} icon={<DeleteOutlined/>} onClick={() => props.onDelete?.(props.record)} />
            </>
        )}
    </Flex>
);

export default RowOps;
