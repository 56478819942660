import React, {useEffect} from 'react';
import {Form, Select} from 'antd';

import type {MeetingType} from 'types';
import {selectOptions} from 'utils';
import {useMeetingTypes} from 'hooks/useMeetingTypes';

const MeetingTypeFormItem = (props: {
    meetingType?: MeetingType,
    setMeetingType: (meetingType: MeetingType) => void;
}) => {
    const {meetingType, setMeetingType} = props;
    const meetingTypes = useMeetingTypes();
    const options = selectOptions(meetingTypes);

    useEffect(() => {
        if (meetingTypes && meetingTypes.length === 1 && !meetingType) {
            setMeetingType(meetingTypes[0]);
        }
    }, [meetingType, setMeetingType, meetingTypes]);

    const onChange = (meetingTypeId: string) => {
        const meetingType = meetingTypes.find(meetingType => meetingType.id === meetingTypeId);
        if (meetingType) {
            setMeetingType({...meetingType});
        }
    }

    return (
        <Form.Item
            className={'formControl'}
            label={'What type of meeting is this?'}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required
        >
            <Select
                placeholder="Please select"
                value={meetingType?.id}
                onChange={onChange}
                options={options}
            />
        </Form.Item>
    )
}

export default MeetingTypeFormItem;
