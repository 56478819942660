import {useEffect} from 'react';

import {MeetingType} from 'types';
import {useAppDispatch, useAppSelector} from 'store';
import {fetchMeetingTypes, selectMeetingTypes, selectMeetingTypeStatus} from 'features/meetingTypeSlice';
import {nameSort} from 'utils';

export function useMeetingTypes(): MeetingType[] {
    const dispatch = useAppDispatch();
    const meetingTypes = useAppSelector(selectMeetingTypes);
    const meetingTypeStatus = useAppSelector(selectMeetingTypeStatus);

    useEffect(() => {
        if (meetingTypeStatus === 'idle') {
            dispatch(fetchMeetingTypes());
        }
    }, [meetingTypeStatus, dispatch]);

    return [...meetingTypes].sort(nameSort);
}

export function useMeetingType(meetingTypeId: string|undefined) {
    const meetingTypes = useMeetingTypes();
    return meetingTypes.find(meetingType => meetingType.id === meetingTypeId);
}
