import {Button, Modal} from 'antd';

import SearchInput from './SearchInput';

const SearchModal = (props: {
    open: boolean,
    onSelect: (url: string) => void;
    onCancel?: () => void;
}) => {
    return (
        <Modal
            title={null}
            closable={false}
            open={props.open}
            onCancel={props.onCancel}
            footer={[
                <Button key="back" onClick={props.onCancel}>
                    Cancel
                </Button>,
            ]}
            destroyOnClose
        >
            <SearchInput onChange={props.onSelect}/>
        </Modal>
    );
}

export default SearchModal;
