import * as d3 from 'd3';


function timerRun(f: (t: number) => void, duration: number = 750) {
    const timer = d3.timer((elapsed) => {
        const t = Math.min(elapsed / duration, 1);
        f(d3.easeSin(t));
        if (t === 1) {
            // stop this timer since we are done animating.
            timer.stop();
        }
    });
}

export default timerRun;
