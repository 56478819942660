import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {App, Form, Input, Row, Col} from 'antd';

import {ErrorDetail, ErrorDetailDispatch, MeetingType} from 'types';
import {getErrorMessage} from 'utils';
import {AppDispatch, useAppDispatch} from 'store';
import {createMeetingType, updateMeetingType} from 'features/meetingTypeSlice';
import SettingsCreateEditPage from 'pages/settings/_common/SettingsCreateEditPage';
import SettingsSectionTitle from 'pages/settings/_common/SettingsSectionTitle';
import SettingsButtonBar from 'pages/settings/_common/SettingsButtonBar';

import MeetingTypeColumnsTable from './MeetingTypeColumnsTable';
import DeleteMeetingTypeButton from './DeleteMeetingTypeButton';


const CreateEditMeetingTypePage = (props: {
    meetingType?: MeetingType
}) => {
    const navigate = useNavigate();
    const {message} = App.useApp();
    const [form] = Form.useForm();
    const [columnNames, setColumnNames] = useState<string[]>(() => {
        return props.meetingType?.columns ? props.meetingType.columns : ['Backlog', 'Discussion', 'Next Actions']
    });
    const dispatch: AppDispatch = useAppDispatch();
    const {meetingType} = props;

    useEffect(() => {
        if (meetingType && form) {
            form.setFieldsValue(meetingType);
        }
    }, [meetingType, form]);

    async function onCreate(values: MeetingType) {
        try {
            values.columns = columnNames;
            const res = await dispatch(createMeetingType(values));
            if ((res as ErrorDetailDispatch<MeetingType>).error) {
                return message.error((res.payload as ErrorDetail).detail);
            }

            message.success(`Meeting type created.`);
            navigate('/settings#meeting-types');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onEdit(values: MeetingType) {
        try {
            values.id = props.meetingType!.id;
            values.columns = columnNames;
            const res = await dispatch(updateMeetingType(values));
            if ((res as ErrorDetailDispatch<MeetingType>).error) {
                return message.error((res.payload as ErrorDetail).detail);
            }

            message.success(`Meeting type updated.`);
            navigate('/settings#meeting-types');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onFinish(values: MeetingType) {
        return props.meetingType ? onEdit(values) : onCreate(values);
    }

    return (
        <SettingsCreateEditPage breadcrumbs={[
            {title: <Link to={'/settings'}>Settings</Link>},
            {title: <Link to={'/'}>Home</Link>}
        ]}>
            <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<MeetingType>
                            label="Name"
                            name="name"
                            className="formControl"
                            rules={[{required: true, message: 'Please input a name!'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item<MeetingType>
                            label="Description"
                            name="description"
                            className="formControl"
                        >
                            <Input.TextArea/>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{marginBottom: '25px'}}>
                    <SettingsSectionTitle>Column Names</SettingsSectionTitle>
                    <MeetingTypeColumnsTable columnNames={columnNames} setColumnNames={setColumnNames}/>
                </div>

                <SettingsButtonBar
                    extraButtons={props.meetingType ? <DeleteMeetingTypeButton meetingTypeId={props.meetingType.id}/> : undefined }
                />
            </Form>
        </SettingsCreateEditPage>
    );
}

export default CreateEditMeetingTypePage;
