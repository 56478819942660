import CreateRoleSteps from 'components/steps/roles/CreateRoleSteps';
import CreateEditModal from 'components/steps/_common/CreateEditModal';

import {Team, Zone, Role, ObjectType} from 'types';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';
import CreateTeamSteps from 'components/steps/teams/CreateTeamSteps';
import AssignPersonToRoleSteps from 'components/steps/roles/AssignPersonToRoleSteps';
import type {MenuProps} from 'antd';
import React from 'react';
import {CPGNode} from '../../components/canvas/cpg/types';

export type NodeAction = 'add-role'|'add-zone'|'add-team'|'assign-user-to-role';

export const zoneMenuItems: MenuProps['items'] = [
    {
        label: 'Add Zone',
        key: 'add-zone',
    },
    {
        label: 'Add Team',
        key: 'add-team',
    }
];

export const teamMenuItems: MenuProps['items'] = [
    {
        label: 'Add Role',
        key: 'add-role',
    }
];

export const roleMenuItems: MenuProps['items'] = [
    {
        label: 'Assign Role',
        key: 'assign-user-to-role',
    }
];

export function getByType(objectType: ObjectType) {
    switch (objectType) {
        case 'zone':
            return zoneMenuItems;
        case 'team':
            return teamMenuItems;
        case 'role':
            return roleMenuItems;
    }
    return undefined
}

const NodeActions = (props: {
    action: NodeAction|undefined,
    node: CPGNode|undefined,
    onClose: () => void
}) => {
    if (!props.node) return null;
    return (
        <div>
            <CreateEditModal
                title={`Add role to ${props.node.obj.name}`}
                open={props.action === 'add-role'}
                onCancel={props.onClose}
            >
                <CreateRoleSteps
                    team={props.node.obj as Team|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Add zone to ${props.node.obj.name}`}
                open={props.action === 'add-zone'}
                onCancel={props.onClose}
            >
                <CreateZoneSteps
                    zone={props.node.obj as Zone|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Add team to ${props.node.obj.name}`}
                open={props.action === 'add-team'}
                onCancel={props.onClose}
            >
                <CreateTeamSteps
                    zone={props.node.obj as Zone|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
            <CreateEditModal
                title={`Assign User to ${props.node.obj.name}`}
                open={props.action === 'assign-user-to-role'}
                onCancel={props.onClose}
                width={600}
            >
                <AssignPersonToRoleSteps
                    role={props.node.obj as Role}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateEditModal>
        </div>
    );
}

NodeActions.getByType = getByType;

export default NodeActions;
