import {useState} from 'react';
import {Drawer} from 'antd';

import type {BaseObject, Role, Team, Zone, Goal, Decision, ServiceAgreement} from 'types';

import styles from './styles.module.scss';
import {titleCase} from 'utils';

import ZoneDrawerContents from './zone/ZoneDrawerContents';
import TeamDrawerContents from './team/TeamDrawerContents';
import RoleDrawerContents from './role/RoleDrawerContents';
import GoalDrawerContents from './goal/GoalDrawerContents';
import DecisionDrawerContents from './decision/DecisionDrawerContents';
import ServiceAgreementDrawerContents from './serviceAgreement/ServiceAgreementDrawerContents';

import ObjectDrawerButtons from './ObjectDrawerButtons';

const ObjectDrawer = (props: {
    obj?: BaseObject,
    open: boolean,
    onClose: () => void,
}) => {
    const name = props.obj ? titleCase(props.obj.type) : '';
    const [edit, setEdit] = useState(false);

    function onClose() {
        setEdit(false);
        props?.onClose();
    }

    return (
        <Drawer
            className={styles.drawer}
            title={props.obj?.name}
            onClose={onClose}
            open={props.open}
            closable={false}
            extra={<ObjectDrawerButtons name={name} edit={edit} setEdit={setEdit} onClose={onClose} />}
            destroyOnClose
        >
            {props.obj?.type === 'zone' ?
                <ZoneDrawerContents zone={props.obj as Zone} edit={edit} setEdit={setEdit}/> : null}
            {props.obj?.type === 'team' ?
                <TeamDrawerContents team={props.obj as Team} edit={edit} setEdit={setEdit}/> : null}
            {props.obj?.type === 'role' ?
                <RoleDrawerContents edit={edit} setEdit={setEdit} role={props.obj as Role}/> : null}
            {props.obj?.type === 'goal' ?
                <GoalDrawerContents goal={props.obj as Goal} edit={edit} setEdit={setEdit}/> : null}
            {props.obj?.type === 'decision' ?
                <DecisionDrawerContents decision={props.obj as Decision} edit={edit} setEdit={setEdit}/> : null}
            {props.obj?.type === 'service_agreement' ?
                <ServiceAgreementDrawerContents
                    serviceAgreement={props.obj as ServiceAgreement}
                    edit={edit}
                    setEdit={setEdit}/> : null
            }
        </Drawer>
    );
}

export default ObjectDrawer;
