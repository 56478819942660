import {Button, Space, Form, Flex} from 'antd';
import {ReactNode} from 'react';

const FormButtonBar = (props: {
    onCancel?: () => void,
    extra?: ReactNode
}) => {
    return (
        <Form.Item>
            <Flex justify={'space-between'}>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button type="default" style={{marginRight: '8px'}} onClick={props.onCancel}>
                        Cancel
                    </Button>
                </Space>
                {props.extra ? props.extra : null}
            </Flex>
        </Form.Item>
    );
}

export default FormButtonBar;
