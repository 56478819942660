import React, {useState} from 'react';
import type {DragEndEvent} from '@dnd-kit/core';
import {DndContext} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {ButtonProps, Form, Table} from 'antd';
import type {TableColumnsType} from 'antd';
import type {ColumnType} from 'antd/es/table';

import styles from './styles.module.scss';
import DragHandle from './DragHandle';
import RowOps from './RowOps';
import EditableCell, {type EditableCellProps} from './EditableCell';
import EditableRow from './EditableRow';
import AddButton from './AddButton';
import {equalExceptKey} from './utils';
import {isDefined} from 'utils';

/* 'key' is reserved. */
export type DataType = {
    [key: string]: string;
}

const EditableDnDTable = (props: {
    values: DataType[],
    setValues: (values: DataType[]) => void,
    columns: ColumnType<DataType>[],
    showHeader?: boolean
    buttonProps?: Omit<ButtonProps, 'onClick'>
    nonEmpty?: boolean
    uniqueOnly?: boolean
}) => {
    /* Each row needs a persistent key, so we have to keep our own state. */
    const [dataSource, setDataSource] = useState<DataType[]>(() => {
        return props.values.map(value => {
            const key = value.key ? value.key : crypto.randomUUID();
            return {...value, key};
        })
    });

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [addedKey, setAddedKey] = useState('');

    const isEditing = (record: DataType) => record.key === editingKey;

    const onEdit = (record: DataType) => {
        form.setFieldsValue(record);
        setEditingKey(record.key);
    }

    const onConfirm = async (record: DataType) => {
        try {
            const row = (await form.validateFields()) as DataType;

            const newDataSource = [...dataSource];
            const index = newDataSource.findIndex((item) => record.key === item.key);
            if (index > -1) {
                const item = newDataSource[index];
                newDataSource.splice(index, 1, {
                    ...item,
                    ...row,
                });
            } else {
                newDataSource.push(row);
            }
            props.setValues(newDataSource);
            setDataSource(newDataSource);
            setEditingKey('');
            setAddedKey('');
            form.resetFields();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onCancel = () => {
        if (addedKey) {
            const newDatasource = dataSource.filter(item => item.key !== addedKey);
            setDataSource(newDatasource);
            setAddedKey('');
        }
        setEditingKey('');
    }

    const onAdd = () => {
        const key = crypto.randomUUID();
        const newDataSource = [...dataSource, {key} as DataType];
        setDataSource(newDataSource);
        setAddedKey(key);
        setEditingKey(key);
        form.resetFields();
    }

    const onDelete = async (record: DataType) => {
        const newDataSource = dataSource.filter(item => record.key !== item.key);
        props.setValues(newDataSource);
        setDataSource(newDataSource);
        setEditingKey('');
        form.resetFields();
    };

    const columns: TableColumnsType<DataType> = [
        {key: 'sort', align: 'center', width: 48, render: () => <DragHandle/>},
        ...props.columns.map(((column, index) => {
            const key = column.key ? column.key : `${index}`;
            return {
                ...column,
                key,
                onCell: (record: DataType) => ({
                    record,
                    inputType: 'text',
                    dataIndex: column.dataIndex,
                    title: column.title ? `${column.title}` : undefined,
                    editing: isEditing(record),
                })
            }
        })),
        {
            key: 'operation', dataIndex: 'operation', width: '1%', render: (_: any, record: DataType) => {
                const editable = isEditing(record);
                return <RowOps
                    record={record}
                    editable={editable}
                    onEdit={onEdit}
                    onConfirm={onConfirm}
                    onDelete={(!props.nonEmpty || dataSource.length > 1) ? onDelete : undefined}
                    onCancel={onCancel}
                />
            }
        },
    ];

    const onDragEnd = ({active, over}: DragEndEvent) => {
        if (active.id !== over?.id) {
            const activeIndex = dataSource.findIndex((record) => record.key === active?.id);
            const overIndex = dataSource.findIndex((record) => record.key === over?.id);
            const newDataSource = arrayMove(dataSource, activeIndex, overIndex);
            props.setValues(newDataSource);
            setDataSource(newDataSource);
        }
    };

    const rowUniqueValidator = () => {
        const data = form.getFieldsValue() as DataType;
        for (const x of dataSource) {
            if (equalExceptKey(x, data)) {
                return Promise.reject('Rows must be unique.');
            }
        }
        return Promise.resolve();
    }

    const showHeader = isDefined(props.showHeader) ? props.showHeader : false;
    const {className, ...buttonProps} = props.buttonProps || {};
    const buttonClassName = className ? `${className} styles.button` : styles.button;
    return (
        <Form form={form} component={false}>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext items={dataSource.map(item => item.key)} strategy={verticalListSortingStrategy}>
                    <Table<DataType>
                        className={styles.table}
                        rowKey="key"
                        components={{
                            body: {
                                row: EditableRow,
                                cell: (cellProps: EditableCellProps) => (
                                    <EditableCell {...cellProps} validator={props.uniqueOnly ? rowUniqueValidator : undefined}/>
                                )
                            }
                        }}
                        columns={columns}
                        dataSource={dataSource}
                        showHeader={showHeader}
                        pagination={false}
                    />
                </SortableContext>
            </DndContext>
            <AddButton disabled={!!editingKey} className={buttonClassName} onClick={onAdd} {...buttonProps} />
        </Form>
    );
};

export default EditableDnDTable;
