import {Card, Typography} from 'antd';
import {Goal} from 'types';

import styles from './styles.module.scss';

const GoalCard = (props: {
    goal: Goal,
    onClick?: () => void
}) => {
    return (
        <Typography.Link onClick={props.onClick}>
            <Card className={styles.card} style={{marginBottom:'8px'}}>
                <Typography.Title level={3}>{props.goal.name}</Typography.Title>
            </Card>
        </Typography.Link>
    );
}

export default GoalCard;
