import React, {useState} from 'react';

import {map} from 'utils';

import type {CPGNode} from 'components/canvas/cpg/types';
import Loading from 'components/Loading';
import CirclePackingGraph from 'components/canvas/cpg/CirclePackingGraph';
import ObjectDrawer from 'components/drawers/ObjectDrawer';

import styles from './styles.module.scss';

import {useAppSelector} from 'store';
import {getZoneFilters} from 'features/zoneFilterSlice';

import NodeActions, {type NodeAction} from './NodeActions';
import ZoneChartLegend from './legend/ZoneChartLegend';

import {useZoneChartData} from 'hooks/useData';
import NoData from './NoData';
import NodeMenu, {MenuItem} from './NodeMenu';
import data from './data';

type ContextMenu = {
    node: CPGNode,
    x: number,
    y: number,
}

function onTooltipText(node: CPGNode|undefined) {
    return node?.obj.name;
}

const ZoneChartPage = () => {
    const [node, setNode] = useState<CPGNode|undefined>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menu, setMenu] = useState<ContextMenu>();

    const [action, setAction] = useState<NodeAction>();
    const zoneFilters = useAppSelector(getZoneFilters);
    const objs = useZoneChartData();

    if (!objs) {
        return (<Loading/>);
    }

    if (objs['zones'].length === 0) {
        return (<NoData />)
    }

    function onDoubleClick(node: CPGNode|undefined) {
        setNode(node);
        setDrawerOpen(true);
    }

    function onContextMenu(node: CPGNode|undefined, x: number, y: number) {
        if (node) {
            setMenu({node, x, y})
        }
    }

    function onMenuClick(item: MenuItem) {
        if (item && item.key && menu) {
            setNode(menu.node);
            setAction(item.key as NodeAction);
        }
        setMenu(undefined);
    }

    const people = map(objs['people']);
    const cpgData = data(objs['org'], objs['zones'], zoneFilters, objs['teams'], objs['roles'], people);

    return (
        <>
            <div className={styles.container} onClick={() => setMenu(undefined)}>
                {cpgData.root ?
                    <>
                        <CirclePackingGraph
                            {...cpgData}
                            onDoubleClick={onDoubleClick}
                            onContextMenu={onContextMenu}
                            onTooltipText={onTooltipText}
                        />
                        <ZoneChartLegend />
                    </>:
                    null
                }
            </div>
            {node ?
                <ObjectDrawer
                    obj={node?.obj}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                /> : null
            }
            <NodeActions node={node} action={action} onClose={() => setAction(undefined)} />
            {menu ? <NodeMenu {...menu} onClick={onMenuClick}/> : null}
        </>
    );
}

export default ZoneChartPage;
