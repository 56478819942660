import React, {useEffect, useState} from 'react';
import type {RadioChangeEvent} from 'antd';
import {Button, Popover, Radio, Space} from 'antd';

import {useAppSelector, useAppDispatch} from 'store';

import type {ThemeMode} from 'types';
import SunIcon from 'icons/sun';

import {getThemeMode, setThemeMode} from 'features/themeModeSlice';

import styles from './styles.module.scss';

const ThemeSwitcherContent = (props: {
    value: ThemeMode,
    onChange: (themeMode: ThemeMode) => void
}) => {

    function onChange(e: RadioChangeEvent) {
        props.onChange(e.target.value as ThemeMode);
    }

    return (
        <Radio.Group onChange={onChange} value={props.value}>
            <Space direction="vertical">
                <Radio value={'system'}>System</Radio>
                <Radio value={'light'}>Light</Radio>
                <Radio value={'dark'}>Dark</Radio>
            </Space>
        </Radio.Group>
    );
}

const ThemeSwitcher = () => {
    const themeModeValue = useAppSelector(getThemeMode) as ThemeMode;
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const root = document.documentElement;

        if (themeModeValue === 'system') {
            // System theme: Apply based on user's OS settings
            const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            root.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
        } else {
            // Apply theme based on the selected option
            root.setAttribute('data-theme', themeModeValue);
        }
    }, [themeModeValue]);

    function onChange(mode: ThemeMode) {
        dispatch(setThemeMode(mode));
        setOpen(false);
    }

    return (
        <Popover
            className={`${styles.popover} h-theme-switch`}
            content={<ThemeSwitcherContent value={themeModeValue} onChange={onChange}/>}
            trigger="click"
            open={open}
            onOpenChange={setOpen}
        >
            <Button
                className={styles.button}
                type={'link'}
                icon={<SunIcon/>}
            />
        </Popover>
    )
}

export default ThemeSwitcher;
