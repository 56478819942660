import {useEffect, useState} from 'react';
import {App} from 'antd';

import type {Create, Meeting, MeetingElement, MeetingType, Team} from 'types';
import {getErrorMessage} from 'utils';
import {createMeeting, updateMeeting} from 'services/meetings';

import {useOrg} from 'hooks/useOrg';
import {useMeetingTypes} from 'hooks/useMeetingTypes';

import CreateEditSteps from 'components/steps/_common/CreateEditSteps';
import NameStep from 'components/steps/_common/NameStep';
import MeetingTypeFormItem from './MeetingTypeFormItem';
import MeetingElementsFormItem from './MeetingElementsFormItem';

const DEFAULT_MEETING_ELEMENTS = [
    {title: 'Check-in', duration: '5m'},
    {title: 'Create Agenda', duration: '5m'},
    {title: 'Updates', duration: '5m'},
    {title: 'Discussion', duration: '30m'},
];


const CreateEditMeetingSteps = (props: {
    team: Team,
    meeting?: Meeting
    onSuccess: (meeting: Meeting) => void,
    onCancel: () => void,
}) => {
    const {message} = App.useApp();
    const org = useOrg();

    const meetingTypes = useMeetingTypes();
    const [meetingType, setMeetingType] = useState<MeetingType>();
    const [meeting, setMeeting] = useState(props.meeting ? props.meeting : {} as Meeting);
    const [elements, setElements] = useState<MeetingElement[]>(props.meeting ? props.meeting.elements : DEFAULT_MEETING_ELEMENTS);

    useEffect(() => {
        if (!meetingType && meetingTypes && props.meeting?.meeting_type?.id) {
            const newMeetingType = meetingTypes.find(m => m.id === props.meeting?.meeting_type?.id);
            if (newMeetingType) {
                setMeetingType({...newMeetingType});
            }
        }
    }, [meetingType, meetingTypes, props.meeting?.meeting_type?.id]);

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setMeeting(props.meeting ? props.meeting : {} as Meeting);
        }, 0);
    }

    async function onCreate() {
        try {
            const newMeeting = {
                ...meeting,
                meeting_type: {id: meetingType?.id, type: 'meeting_type'},
                team: {id: props.team.id, type: 'team'},
                elements
            } as Create<Meeting>;
            const res = await createMeeting(org!, newMeeting);
            props.onSuccess(res);
            message.success('Your meeting was created successfully.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onUpdate() {
        try {
            const newMeeting = {
                ...meeting,
                meeting_type: {id: meetingType?.id, type: 'meeting_type'},
                team: {id: props.team.id, type: 'team'},
                elements
            } as Meeting;
            const res = await updateMeeting(org!, newMeeting);
            props.onSuccess(res);
            message.success('Your meeting was updated successfully.');
        } catch (e) {
            message.error(getErrorMessage(e));
        }
    }

    async function onConfirm() {
        return props.meeting ? onUpdate() : onCreate();
    }

    const steps = [
        {
            title: 'Meeting Type',
            content:
                <MeetingTypeFormItem
                    meetingType={meetingType}
                    setMeetingType={meetingType => {
                        if (!meeting.name) {
                            setMeeting({...meeting, name: meetingType.name});
                        }
                        setMeetingType(meetingType);
                    }}
                />,
            valid: () => !!meetingType,
        },
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this meeting be called?"
                    name={meeting.name || ''}
                    setName={name => {
                        setMeeting({...meeting, name});
                    }}
                />,
            valid: () => !!meeting.name,
        },
        {
            title: 'Elements',
            content:
                <MeetingElementsFormItem
                    elements={elements}
                    setElements={setElements}
                />,
            valid: () => true,
        }
    ];

    return (
        <CreateEditSteps
            steps={steps}
            onCreate={onConfirm}
            onCancel={onCancel}
            actionText={props.meeting ? 'Update' : 'Create'}
        />
    )
}

export default CreateEditMeetingSteps;
