import type {Keyed, Person, Organization, BaseObject, ErrorDetail, ErrorDetailDispatch} from 'types';

export function isString(s: any) {
    return (typeof s === 'string' || s instanceof String);
}

export function initials(name: string) {
    const words = name.split(' ');
    if (words.length >= 2) {
        return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    }
    if (words.length === 1) {
        return `${words[0][0].toUpperCase()}`;
    }
    return '?'
}

export function trimSlashes(value: string|undefined) {
    return value !== undefined ? value.replace(/^\/|\/$/g, '') : undefined;
}

export function userTypeDisplay(userType: Person['userType']) {
    switch (userType) {
        case 'read_only': return 'Viewer';
        case 'admin': return 'Administrator';
        case 'regular': return 'Contributor';
    }
    const value = userType?.toString().toUpperCase();
    return value ? value : 'Unknown';
}

export function map<T>(objects: T[]|null|undefined, attr: string = 'id') {
    const map = {} as Record<string, T>;
    if (objects) {
        for (const object of objects) {
            map[(object as Record<string, string>)[attr]] = object;
        }
    }
    return map;
}

export function selectOptions(objects: {id: string, name: string}[]) {
    return objects.map(obj => {
        return {label: obj.name, value: obj.id};
    });
}

export function statusColor(status: string) {
    if (status === 'archived') {
        return 'volcano';
    }
    if (status === 'draft') {
        return 'geekblue';
    }
    if (status === 'active') {
        return 'cyan';
    }
    return 'green';
}

export function getErrorMessage(e: unknown) {
    if (e instanceof Response) {
        return 'Unknown network error.';
    }
    if ((e as {message: string}).message) {
        return (e as {message: string}).message;
    }
    return (e as any).toString();
}

export function nameSort(a: {name: string}, b: {name: string}){
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
        return -1;
    }
    if (x > y) {
        return 1;
    }
    return 0;
}

export function personIsAdmin(person: Person|null|undefined) {
    return person?.userType && ['admin', 'system'].includes(person.userType);
}

export function asDataSource<T extends {id: string}>(objs: {id: string}[]) {
    return (objs as Keyed<T>[]).map(row => {
        return Object.assign({}, row, {key: row.id});
    })
}

export function getOrgFromSession(): Organization|null {
    try {
        const value = localStorage.getItem('org');
        if (value) {
            return JSON.parse(value) as Organization;
        }
    } catch (e) {
        console.log(e);
    }
    return null
}

export function setOrgInSession(org: Organization|null) {
    if (org) {
        localStorage.setItem('org', JSON.stringify(org));
    } else {
        localStorage.setItem('org', '')
    }
}

export function titleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export function getFirstQueryParam(name: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

export function baseUrl() {
    return window.location.origin;
}

export function isDefined(value: any): boolean {
    return (typeof value !== 'undefined');
}

export function isUndefined(value: any): boolean {
    return !isDefined(value);
}

export function isPersonAdmin(person: Person|null) {
    return person ? ['admin', 'system'].includes(person.userType) : false;
}

export function isPersonSystem(person: Person|null) {
    return person?.userType === 'system';
}

export function buildObjectOptions(objs: {[p: string]: {id: string, name: string}[]}) {
    const options: any[] = [];
    for (const prop in objs) {
        if (Object.prototype.hasOwnProperty.call(objs, prop)) {
            if (isDefined(objs[prop])) {
                const objOptions: any[] = []
                objs[prop].forEach(obj => {
                    objOptions.push({label: obj.name, value: JSON.stringify({id: obj.id, type: prop})});
                });

                const optGroup = prop === 'person' ? 'people' : prop + 's';
                options.push({
                    label: optGroup,
                    title: optGroup,
                    options: objOptions
                })
            }
        }
    }
    return options;
}

export function asOptions(objs: BaseObject[]) {
    return objs.map(obj => ({label: obj.name, value:obj.id}));
}

export function base64Encode(arrayBuffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

export function hasDispatchError(res: any): boolean {
    return !!((res as any as ErrorDetailDispatch<BaseObject>).error);
}

export function getDispatchError(res: any): string {
    if (!res.payload) {
        return res.error?.message ? res.error.message : res.error.toString();
    }
    if ((res.payload as ErrorDetail).detail) {
        return (res.payload as ErrorDetail).detail;
    }
    return res.payload.toString();
}

export async function getThunkRejectValue(res: any): Promise<ErrorDetail> {
    if (res.detail) {
        return res as ErrorDetail;
    }
    return await (res as Response).json() as ErrorDetail;
}

export function generateColor(index: number, total: number) {
    // Add 1 to avoid the colors white and black.
    let color = Math.floor((256 * 256 * 256 / (total + 1)) * (index + 1));
    return `#${color.toString(16).padStart(6, '0')}`;
}

export function rgbToHex(r: number, g: number, b: number) {
    if (r > 255 || g > 255 || b > 255) {
        return '#fff';
    }
    return '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}

export function isTeamPage(teamId: string|undefined = undefined) {
    return teamId ?
        window.location.pathname.startsWith(`/teams/${teamId}`) :
        window.location.pathname.startsWith('/teams/');
}
