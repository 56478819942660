import * as d3 from 'd3';

// import forceBoundary from 'd3-force-boundary';
import {CPGNode} from './types';

function pack(props: {
    width: number,
    height: number,
    node: CPGNode
}) {
    const hierarchy = d3.hierarchy<CPGNode>(props.node)
        .sum(d => {
            return d.value || 0
        })
        .sort((a, b) => {
            return (b.value || 0) - (a.value || 0);
        });

    // Compute the layout.
    const pack = () => d3.pack<CPGNode>()
        .size([props.width, props.height])
        .padding(8)
        (hierarchy);

    return pack();
}

export default pack;
