import {PropsWithChildren} from 'react';

import type {Team} from 'types';
import {isTeamPage} from 'utils';
import {useTeam} from 'hooks/useTeams';
import TeamMeetingProvider from 'providers/TeamMeetingProvider';
import TeamForm from 'components/forms/TeamForm';

import TeamDrawerDisplay from './TeamDrawerDisplay';
import TeamMeetingsTable from './meetingTable/TeamMeetingsTable';

const TeamProvider = (props: PropsWithChildren<{team: Team}>) => {
    if (isTeamPage(props.team.id)) {
        return <>{props.children}</>;
    }
    return <TeamMeetingProvider team={props.team}>{props.children}</TeamMeetingProvider>
}


const TeamDrawerContents = (props: {
    team: Team | undefined,
    edit?: boolean,
    setEdit?: (value: boolean) => void
}) => {
    /* This redundancy forces a re-render on team data update. */
    const team = useTeam(props.team?.id);
    const onFinish = () => props.setEdit?.(false);

    if (!team) return null;

    if (props.edit) {
        return (
            <TeamProvider team={team}>
                <TeamForm team={team} onSave={onFinish} onCancel={onFinish}/>
                <TeamMeetingsTable team={team} />
            </TeamProvider>
        );
    }

    return <TeamDrawerDisplay team={team}/>;
}

export default TeamDrawerContents;
