import {Radio, Select} from 'antd';
import {Meeting} from 'types';
import {asOptions} from 'utils';

const TeamMeetingSelector = (props: {
    className?: string,
    meeting: Meeting | undefined,
    setMeeting: (meeting: Meeting) => void,
    meetings: Meeting[]
}) => {
    const options = asOptions(props.meetings);
    if (!props.meetings || props.meetings.length < 2) return null;

    const value = props.meeting ? props.meeting.id : props.meetings[0].id;

    const onChange = (meetingId: string) => {
        const meeting = props.meetings.find(m => m.id === meetingId);
        if (meeting) {
            props.setMeeting(meeting);
        }
    }

    if (props.meetings.length < 4) {
        return (
            <Radio.Group
                className={props.className}
                defaultValue={value}
                options={options}
                optionType="button"
                onChange={e => onChange(e.target.value)}
                style={{whiteSpace: 'nowrap'}}
            />
        );
    }

    return (
        <Select
            className={props.className}
            value={value}
            onChange={onChange}
        />
    );
}

export default TeamMeetingSelector;
