import {CSSProperties, useState} from 'react';
import {Button} from 'antd';
import {Field} from 'types';
import EditFieldModal from './EditFieldModal';

const AddField = (props: {
    style?: CSSProperties,
    onAdd: (field: Field) => void
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function onFinish(field: Field) {
        setIsModalOpen(false);
        props.onAdd(field);
    }

    return (
        <>
            <div className='add-field' style={props.style}>
                <Button type="default" onClick={showModal}>
                    Add Field
                </Button>
            </div>
            <EditFieldModal title={'Add Field'} open={isModalOpen} onCancel={handleCancel} onSave={onFinish} />
        </>
    );
}

export default AddField;
