import {CSSProperties} from 'react';
import {Menu, MenuProps} from 'antd';

import {CPGNode} from 'components/canvas/cpg/types';
import {BaseObject} from 'types';
import NodeActions from './NodeActions';

export type MenuItem = Required<MenuProps>['items'][number];

const NodeMenu = (props: {
    onClick?: MenuProps['onClick']
    node: CPGNode,
    x: number,
    y: number,
}) => {
    const style = {
        width: 256,
        position: 'fixed',
        left: props.x,
        top: props.y,
    } as CSSProperties;

    const obj = props.node.obj as BaseObject;
    const items = NodeActions.getByType(obj.type);
    if (!items) return null;

    return (
        <Menu
            onClick={props.onClick}
            style={style}
            mode="inline"
            items={items}
        />
    );
}

export default NodeMenu;
