import {useState} from 'react';
import {Button, type ButtonProps} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import {AgendaItem, Meeting} from 'types';
import CreateEditModal from 'components/steps/_common/CreateEditModal';
import AgendaItemForm from 'components/forms/AgendaItemForm';
import {useAddAgendaItem} from 'hooks/meetings';

const AddAgendaItemButton = (props: Omit<ButtonProps, 'onClick'> & {
    meeting: Meeting,
    order: number,
    column: string,
    onCreate?: (agendaItem: AgendaItem) => void,
}) => {
    const [open, setOpen] = useState(false);
    const addAgendaItem = useAddAgendaItem();

    const onCancel = () => setOpen(false);
    const onFinish = (agendaItem: AgendaItem) => {
        addAgendaItem(agendaItem);
        props.onCreate?.(agendaItem);
        setOpen(false);
    }

    const onClick = () => {
        setOpen(true);
    }

    return (
        <>
            <Button type={'link'} icon={<PlusOutlined/>} onClick={onClick}/>
            <CreateEditModal
                open={open}
                title={'Add Agenda Item'}
                onCancel={onCancel}
                width={500}
            >
                <AgendaItemForm {...props} onFinish={onFinish} onCancel={onCancel} />
            </CreateEditModal>
        </>
    );
}

export default AddAgendaItemButton;
