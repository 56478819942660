import {useState} from 'react';
import {App} from 'antd';

import {Role, Team, PersonRef, TemplateRef, ErrorDetailDispatch, ErrorDetail} from 'types';
import {AppDispatch, useAppDispatch} from 'store';

import {map} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {useTemplates} from 'hooks/useTemplates';

import CreateEditSteps from 'components/steps/_common/CreateEditSteps';
import NameStep from 'components/steps/_common/NameStep';
import TemplateStep from 'components/steps/_common/TemplateStep';
import TeamStep from 'components/steps/_common/TeamStep';

import {createRole} from 'features/roleSlice';
import AccountabilitiesStep from './AccountabilitiesStep';
import templateSteps from 'components/steps/_common/templateSteps';
import AccountableToFormItem from 'components/forms/common/AccountableToFormItem';

const CreateRoleSteps = (props: {
    team?: Team,
    onSuccess: (role: Role) => void,
    onCancel: () => void
}) => {
    const {message} = App.useApp()
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [role, setRole] = useState({} as Role);

    const templates = map(useTemplates());
    const template = role.template?.id ? templates[role.template.id] : undefined;

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setRole({} as Role);
        }, 0);
    }

    async function onCreate() {
        const newRole = {
            ...role,
            owners: [{id: currentUser?.id, type: 'person'} as PersonRef],
        };
        if (props.team) {
            newRole.team = {id: props.team.id, type: 'team'}
        }

        const res = await dispatch(createRole(newRole as Role));
        if ((res as ErrorDetailDispatch<Role>).error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        message.success('Your role was successfully created.')
        props.onSuccess(res.payload as Role);
    }

    const steps = [
        {
            title: 'Role Template',
            content:
                <TemplateStep
                    objectType={'role'}
                    templateId={role.template?.id || ''}
                    setTemplate={(templateId: string) => {
                        const newRole = {
                            ...role, template: {type: 'template', id: templateId} as TemplateRef
                        };
                        if (!newRole.name && props.team) {
                            newRole.name = `${props.team.name} : ${templates[templateId].name}`;
                        }
                        setRole(newRole);
                    }}
                />,
            valid: () => true,
        }
    ];

    if (!props.team) {
        steps.push(
            {
                title: 'Team',
                content:
                    <TeamStep
                        title="What team will have this role?"
                        teamId={role.team?.id || ''}
                        setTeamId={teamId => {
                            const newRole = {...role, team: {id: teamId, type: 'team'}} as Role;
                            setRole(newRole);
                        }}
                    />,
                valid: () => !!role.team?.id
            }
        )
    }

    steps.push(
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this role be called?"
                    name={role.name || ''}
                    setName={name => {
                        setRole({...role, name});
                    }}
                />,
            valid: () => !!role.name,
        },
        {
            title: 'Accountabilities',
            content:
                <AccountabilitiesStep
                    accountabilities={role.accountabilities || ''}
                    setAccountabilities={accountabilities => {
                        setRole({...role, accountabilities});
                    }}
                />,
            valid: () => !!role.accountabilities,
        },
        {
            title: 'Accountable To',
            content:
                <AccountableToFormItem
                    roleId={role.accountable_to?.id || ''}
                    setRoleId={id => {
                        if (id) {
                            setRole({...role, accountable_to: {id: id, type: 'role'}});
                        } else {
                            setRole({...role, accountable_to: null});
                        }
                    }}
                />,
            valid: () => true
        }
    )

    return (
        <>
            <CreateEditSteps
                steps={templateSteps(role, setRole, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateRoleSteps;
