import {App, Card, Flex, Typography} from 'antd';

import EditableAvatar from 'components/avatar/EditableAvatar';

import styles from './styles.module.scss';
import type {BaseObject, ObjectPictures, Organization} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {useOrg} from 'hooks/useOrg';
import {updateOrganization} from 'features/orgSlice';
import {getDispatchError, hasDispatchError} from 'utils';

const OrgCard = () => {
    const {message} = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();
    const organization = useOrg();
    if (!organization) return null;

    async function onNameChange(name: string) {
        const org = {...organization, name} as Organization;
        const res = await dispatch(updateOrganization(org));
        if (hasDispatchError(res)) {
            return message.error(getDispatchError(res));
        }
    }

    async function handleAvatarChange(obj: BaseObject & ObjectPictures) {
        const update = {...organization, ...obj} as Organization;
        const res = await dispatch(updateOrganization(update));
        if (hasDispatchError(res)) {
            await message.error(getDispatchError(res));
            return false;
        }
        return true;
    }

    return (
        <Card>
            <Flex align={'center'}>
                <EditableAvatar obj={organization} size={64} updateObject={handleAvatarChange}/>
                <Typography.Paragraph
                    className={styles.title}
                    editable={{triggerType: ['text'], onChange: onNameChange}}
                >
                    {organization.name}
                </Typography.Paragraph>
            </Flex>
        </Card>
    );
}

export default OrgCard;
