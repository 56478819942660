import {Button, Space, Tooltip} from 'antd';
import {CloseOutlined, EditOutlined} from '@ant-design/icons';

const ObjectDrawerEdit = (props: {
    name: string,
    edit: boolean,
    setEdit: (value: boolean) => void
}) => {
    return (
        <Tooltip title={`Edit ${props.name}`}>
            <Button
                shape="circle"
                icon={props.edit ? <CloseOutlined/> : <EditOutlined/>}
                onClick={() => props.setEdit(!props.edit)}
            />
        </Tooltip>
    );
}

const ObjectDrawerClose = (props: {
    onClose: () => void
}) => {
    return (
        <Tooltip title={'Close'}>
            <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={props.onClose}
            />
        </Tooltip>
    );
}

const ObjectDrawerButtons = (props: {
    name: string,
    edit: boolean,
    setEdit: (value: boolean) => void
    onClose: () => void
}) => {
    return (
        <Space>
            {!props.edit && <ObjectDrawerClose onClose={props.onClose}/>}
            <ObjectDrawerEdit name={props.name} edit={props.edit} setEdit={props.setEdit}/>
        </Space>
    );
}

export default ObjectDrawerButtons;
