import React, {PropsWithChildren} from 'react';

import {Button} from 'antd';

import styles from './styles.module.scss';

const SettingsCreateButton = (props: PropsWithChildren<{
    onClick?: () => void,
}>) => {
    return (
        <Button
            className={styles.createButton}
            type={'primary'}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
}

export default SettingsCreateButton;
