import {Breadcrumb, Space, Typography} from 'antd';
import type {BreadcrumbItemType, BreadcrumbSeparatorType} from 'antd/es/breadcrumb/Breadcrumb';

import {useOrg} from 'hooks/useOrg';

import styles from './styles.module.scss';
import ThemeSwitcher from './ThemeSwitcher';
import Profile from './Profile';
import Search from './Search';

const MainHeader = (props: {
    pageTitle: string | null | undefined;
    breadcrumbs:  Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined
}) => {
    const org = useOrg();
    return (
        <header className={styles.header}>
            <div>
                {props.pageTitle ? <Typography.Title style={{textTransform: 'capitalize'}}>{props.pageTitle}</Typography.Title> : null}
                {props.breadcrumbs ? <Breadcrumb style={{margin: '0 0 16px 0'}} items={props.breadcrumbs}/> : null}
            </div>
            <Space align={'center'}>
                <Typography.Text strong>{org?.name}</Typography.Text>
                <Search />
                <ThemeSwitcher />
                <Profile />
            </Space>
        </header>
    );
}

export default MainHeader;
